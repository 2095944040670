import React from "react";
import { useHistory } from "react-router-dom";
import { Result } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";

export default function ProfileSuccess() {
  const history = useHistory();

  return (
    <>
      <Result
        icon={<QuestionCircleOutlined />}
        title="Would you like to invest or raise?"
        extra={
          <Row style={{ marginTop: "20px" }}>
            <Col
              xl={8}
              lg={8}
              md={6}
              sm={2}
              xs={2}
              style={{ marginTop: "100px" }}
            ></Col>

            <Col xl={4} lg={4} md={6} sm={10} xs={10}>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={() => history.push("/invest")}
                className="default-btn"
                style={{ color: "white", width: "150px" }}
              >
                INVEST
              </Button>
            </Col>
            <Col xl={4} lg={4} md={6} sm={10} xs={10}>
              <Button
                type="primary"
                htmlType="submit"
                block
                onClick={() => history.push("/raise")}
                className="default-btn"
                style={{ color: "white", width: "150px" }}
              >
                RAISE
              </Button>
            </Col>
            <Col
              xl={8}
              lg={8}
              md={6}
              sm={2}
              xs={2}
              style={{ marginTop: "100px" }}
            ></Col>
          </Row>
        }
        style={{ marginTop: "188px", padding: "0px" }}
        className="text-grey"
      />
    </>
  );
}
