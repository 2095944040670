import React, { useEffect } from 'react';
import { Table, Spin, message, Typography, Button  } from 'antd';
import { useHistory } from 'react-router';
import DashboardHeader from "../common/header";
import { apiInstance } from '../../config/axios.config';

export default function MileStoneTable() {

    const { Title } = Typography;
    let history = useHistory();
    const [tableData, setTableData] = React.useState()
    const [loading, setLoading] = React.useState(false);
    useEffect(() => {
        getTableData();
    }, []);

    const handleSubmit = () => {
        message.success({ content: 'Your project has been submitted, Our team will be in contact with you soon', duration: 2, style: { marginTop: '20vh' } });
        history.push('/');
}


    const getTableData = async () => {
        try {
            setLoading(true);
            const response = await apiInstance.post('milestones/data', {
                userId: localStorage.getItem('userId'),
            });
            if (response.data.status) {
                console.log(response.data.data.mileStones);
                setLoading(false);
                setTableData(response.data.data.mileStones);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const columns = [
        {
            title: 'Milestone no.',
            dataIndex: 'mileStoneNumber',
            key: 'mileStoneNumber',
        },
        {
            title: 'Milestone Name',
            dataIndex: 'mileStoneName',
            key: 'mileStoneName',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Milestone Status',
            dataIndex: 'mileStoneStatus',
            key: 'mileStoneStatus',
        },
        {
            title: 'Milestone Physical Cash',
            dataIndex: 'mileStonePysicalCash',
            key: 'mileStonePysicalCash',
        },
        {
            title: 'Pre Money Valuation',
            key: 'preMoneyValuation',
            dataIndex: 'preMoneyValuation',
        },
        {
            title: 'Physical Cash Required',
            key: 'physicalCashInvest',
            dataIndex: 'physicalCashInvest',
        },
        {
            title: 'Post Money Valuation',
            key: 'postMoneyEvaluation',
            dataIndex: 'postMoneyEvaluation',
        },
        {
            title: 'Percentage Of Company Acquired',
            key: 'percentageOfCompanyAcquired',
            dataIndex: 'percentageOfCompanyAcquired',
        },
        {
            title: 'Shares Allocated',
            key: 'currentMileStoneSharedAllocated',
            dataIndex: 'currentMileStoneSharedAllocated',
        },
        {
            title: 'Shares Float',
            key: 'shareFloat',
            dataIndex: 'shareFloat',
        },
        {
            title: 'Percentage Owned',
            key: 'percentageOwned',
            dataIndex: 'percentageOwned',
        },
        {
            title: 'Share Price',
            key: 'sharePrice',
            dataIndex: 'sharePrice',
        },
    ];


    return (
        <div>
            <DashboardHeader />
            <div style={{ margin: '30px' }}>
                <Spin spinning={loading} size="large">
                    <Title className="p-14 text-grey" level={5}>Please review the milestones data that you have submitted:</Title>
                    <Table dataSource={tableData} columns={columns} bordered pagination={false} scroll={{x: 100}} />
                </Spin>
                <div className="text-center">

                <Button className="default-btn mt-1 mb-2" onClick={handleSubmit}>Submit</Button>
                </div>

            </div>
        </div>
    );
}