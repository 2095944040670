import React from 'react';
import { Tabs, Row, Col, Radio, Space, Card, Button, Slider, InputNumber, } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import { ProfileTwoTone } from '@ant-design/icons';
import DashboardHeader from "../common/header";

export default function Document() {
    const history = useHistory();
    let [isCounter, setCounter] = React.useState(1);
    const next = () => {
        console.log('i am here ...');
        history.push('/milestone');
    }

    const skip = () => {
        console.log('skip me...');
        history.push('/milestone');
    }

    const gridStyle = {
        width: '100%',
        padding: '30px',
    };

    const spacing = {
        paddingLeft: "30px"
    }

    return (
        <>
            <DashboardHeader />
            <div>
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">
                        <ProfileTwoTone style={{ fontSize: "100px", marginTop: "80px" }} />

                    </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "50px" }}>

                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">

                        <h3 style={{ marginBottom: "10px", fontSize: "20px",  padding: "10px 30px"  }} >
                            Download the Milestones Template as an example to know how to fill out further Milestones Forms
                        </h3>
                    </div>

                    </Col>
                </Row>
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="text-center mb-2">
                    
                
                    {/* <div className="d-flex text-center justify-content-center">     */}
                        <Button className="default-btn" onClick={next}>
                            <a href="https://demec-api.herokuapp.com/logo/doc.xlsx">download</a></Button>
                    &nbsp; &nbsp;
                        <Button className="default-btn" onClick={next}>Skip now</Button>
                    {/* </div> */}
                    </div>    
                    
                    </Col>
                
                </Row>
            </div>

            {/* <Button type="primary" onClick={() => setCounter(++isCounter)}> {isCounter} </Button> */}
        </>
    );
}