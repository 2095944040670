import React, { useState, useEffect, } from 'react';
import DashboardHeader from '../common/header';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Table, message, Card, Typography, } from 'antd';
import { apiInstance } from '../../config/axios.config';
import { WarningTwoTone, } from '@ant-design/icons';

export default function Myprojects() {
    const history = useHistory();
    const [data, setData] = useState();
    const [tableData, setTableData] = useState();
    const [loading, setLoading] = React.useState(false);
    const { Title } = Typography;
    const { Paragraph } = Typography;

    const handleSubmit = () => {
        history.push('/raise');
    }

    useEffect(() => {
        getData();
        getTableData();
    }, []);


    const getData = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await apiInstance.get(`project/${userId}`);
            if (response.data.data.length > 0) {
                console.log(response.data.data);
                setData(response.data.data);
            }
        } catch (error) {
            message.error({
                content: "Something went wrong!",
                style: { marginTop: "20vh" },
            });
        }
    }

    const getTableData = async () => {
        try {
            setLoading(true);
            const response = await apiInstance.post('milestones/data', {
                userId: localStorage.getItem('userId'),
            });
            if (response.data.status) {
                console.log(response.data.data.mileStones);
                setLoading(false);
                setTableData(response.data.data.mileStones);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    const columns = [
        {
            title: 'Milestone no.',
            dataIndex: 'mileStoneNumber',
            key: 'mileStoneNumber',
        },
        {
            title: 'Milestone Name',
            dataIndex: 'mileStoneName',
            key: 'mileStoneName',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Milestone Status',
            dataIndex: 'mileStoneStatus',
            key: 'mileStoneStatus',
        },
        {
            title: 'Milestone Physical Cash',
            dataIndex: 'mileStonePysicalCash',
            key: 'mileStonePysicalCash',
        },
        {
            title: 'Pre Money Valuation',
            key: 'preMoneyValuation',
            dataIndex: 'preMoneyValuation',
        },
        {
            title: 'Physical Cash Required',
            key: 'physicalCashInvest',
            dataIndex: 'physicalCashInvest',
        },
        {
            title: 'Post Money Valuation',
            key: 'postMoneyEvaluation',
            dataIndex: 'postMoneyEvaluation',
        },
        {
            title: 'Percentage Of Company Acquired',
            key: 'percentageOfCompanyAcquired',
            dataIndex: 'percentageOfCompanyAcquired',
        },
        {
            title: 'Shares Allocated',
            key: 'currentMileStoneSharedAllocated',
            dataIndex: 'currentMileStoneSharedAllocated',
        },
        {
            title: 'Shares Float',
            key: 'shareFloat',
            dataIndex: 'shareFloat',
        },
        {
            title: 'Percentage Owned',
            key: 'percentageOwned',
            dataIndex: 'percentageOwned',
        },
        {
            title: 'Share Price',
            key: 'sharePrice',
            dataIndex: 'sharePrice',
        },
    ];

    return (
        <>
            <DashboardHeader />
            <div>
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">

                        <WarningTwoTone style={{ fontSize: "100px", marginTop: "80px" }} />
                    </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "50px" }}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">
                    <p style={{ marginBottom: "10px", fontSize: "20px", padding: "10px 30px" }} >
                            You have not registered any of your projects or companies for a Raise, to do so please click below:
                        </p>
                    </div>

                        
                    </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                <div className="text-center mb-2">

                                <Button className="default-btn" htmlType="submit" onClick={handleSubmit}>Raise</Button>
                                </div>
                            </Col>
                        </Row>
                    
            </div>
        </>
    );
}