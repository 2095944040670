import React, { useState } from 'react';
import { Tabs, Row, Col, Form, Input, Button, Card, Spin, message, Checkbox, Anchor } from 'antd';
import { apiInstance } from '../../config/axios.config';
import { useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import DashboardHeader from './header';
export default function Contact() {
    const { TabPane } = Tabs;
    const { Link } = Anchor;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    function callback(key) {
        console.log(key);
    }
    const onChangeanchoe = (link) => {
        console.log('Anchor:OnChange', link);
    };

    const onFinishLogin = async (values) => {
        try {
            console.log('Success:', values);

            const { email, name, contactMessage, } = values;
            setLoading(true);
            const response = await apiInstance.post('/email/contactus', {
                email: email, name: name, message: contactMessage,
            });
            const { status } = response.data;
            if (status) {
                setLoading(false);
                message.success({ content: 'Contact email sent successfully!', style: { marginTop: '20vh' } });
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            message.error({ content: 'Something went wrong please try again!', style: { marginTop: '20vh' } });
        }
    };


    const onFinishFailedLogin = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = async (values) => {

        try {
            console.log('Success:', values);

            const { email, password, confirmPassword, remember, } = values;

            if (!remember) return message.info({
                content: 'Please accept privacy policy!',
                style: {
                    marginTop: '20vh',
                }
            });

            if (password === confirmPassword) {

                setLoading(true);
                const response = await apiInstance.post('/user/signup', {
                    email: email, password: password,
                });
                const { status } = response.data;
                if (status) {
                    setLoading(false);
                    message.success({ content: 'user created successfully, please check your email to proceed!', style: { marginTop: '20vh' }, duration: 4 });
                }
                else {
                    setLoading(false);
                    message.success('duplicate email address!');
                }
            } else {
                message.info({ content: 'password mismatch', style: { marginTop: '20vh' } });
            }

        } catch (error) {
            setLoading(false);
            message.error({ content: 'email already exists!', style: { marginTop: '20vh' } });
        }



    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const loginauth = () => {
        console.log('ready for login')
    }

    const signUp = () => {
        history.push('/auth');
    }

    const investOppspage = () => {
        history.push('/investopps');
    }

    const gotoContact = () => {
        history.push('/contact');
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    function forgetPassword(e) {
        e.preventDefault();
        console.log('i am hitting ,,,');
        history.push('/forget-password');
    }
    return (
        <Spin size="large" spinning={loading}>
            <DashboardHeader />
            <div>
                <Row>
                    {/* <Col span={8} offset={8} style={{ marginTop: "100px" }}> */}
                    <Col xl={8} lg={8} md={4} sm={2} xs={2} style={{ marginTop: "100px" }}></Col>
                    <Col xl={8} lg={8} md={16} sm={20} xs={20} style={{ marginTop: "100px" }}>
                        <Card className="cardStyle mb-3">
                            <Tabs defaultActiveKey="1" onChange={callback} centered type="card">
                                <TabPane tab="Contact Us" key="1">
                                    <Form
                                        name="basic"
                                        formLayout="vertical"
                                        onFinish={onFinishLogin}
                                        onFinishFailed={onFinishFailedLogin}
                                        formLayout="vertical"
                                    >
                                        <h3 style={{ marginBottom: "10px" }} className="text-grey">
                                            Enter your Name:
                                        </h3>
                                        <Form.Item
                                            name="name"
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                        >
                                            <Input placeholder="Enter your name" size="large" />
                                        </Form.Item>
                                        <h3 style={{ marginBottom: "10px" }}  className="text-grey">
                                            Enter your Email:
                                        </h3>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                        >
                                            <Input placeholder="Email" size="large" />
                                        </Form.Item>

                                        <h3 style={{ marginBottom: "10px" }} className="text-grey">
                                            Type a Message:
                                        </h3>
                                        <Form.Item
                                            name="contactMessage"
                                            rules={[{ required: true, message: 'Please input your message!' }]}
                                        >
                                            <Input.TextArea placeholder="Message..." size="large" rows={4} />
                                        </Form.Item>



                                        <Form.Item>
                                            <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Submit
                                                    </Button> */}
                                                    <div className="text-center">

                                                    <Button className="default-btn" htmlType="submit">Send</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                    <Col xl={8} lg={8} md={4} sm={2} xs={2} style={{ marginTop: "100px" }}></Col>
                </Row>
            </div>
        </Spin>
    );
}