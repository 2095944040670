const axios = require('axios');

const apiInstance = axios.create({
  baseURL: 'https://demec-api.herokuapp.com/',
  // baseURL: 'http://localhost:9999/',
});

module.exports = {
  apiInstance,
}
