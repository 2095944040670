import React, { useState } from 'react';
import {Button} from 'antd'
import './ReadMore.css';

function ReadMore() {
    const [readMore, setReadMore] = useState(false);
    const extraContent = <div>
        <p className="extra-content-readmore">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, consectetur neque ab
            porro quasi culpa nulla rerum quis minus voluptatibus sed hic ad quo sint, libero
            commodi officia aliquam! Maxime.
        </p>
    </div>
    const linkName = readMore ? 'Read Less << ' : 'Read More >> '
    return (
        <div className="readt-more-App">
            <Button className="read-more-linkinreadmore" onClick={() => { setReadMore(!readMore) }}><h2>{linkName}</h2></Button>
            {readMore && extraContent}
        </div>
    );
}


export default ReadMore;