import React from 'react';
import { Tabs, Row, Col, Form, Input, Button, Card, Steps, message, Spin, DatePicker, Select } from 'antd';
import { useHistory } from 'react-router-dom';

export default function Option() {
    const history = useHistory();
    const { TabPane } = Tabs;

    const gridStyle = {
        width: '50%',
        textAlign: 'center',
    };
    return (
        <div>
            <Row style={{ position: "absolute", width: "100%", top:"40%", textAlign: "center" }}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    
                   <h2 className="text-center mb-2 font-16">
                   Would you like to Invest or Raise ? 
                   </h2>
                   <div className="text-center">
                   <Button type="primary" htmlType="submit"  className="default-btn" onClick={() => history.push('/invest')} >
                                INVEST
                            </Button>
                   &nbsp; &nbsp;
                            <Button type="primary" htmlType="submit" className="default-btn" onClick={() => history.push('/raise')} >
                                RAISE
                            </Button>
                  
                   </div>
                       
                    
                </Col>
            </Row>
        </div >
    );
}