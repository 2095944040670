import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Steps,
  message,
  Spin,
  Pagination,
  Select,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";
import DashboardHeader from "../common/header";
import { apiInstance } from "../../config/axios.config";
import axios from "axios";

export default function MilestoneForm() {
  const { Option } = Select;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let [isCurrent, setCurrent] = useState(0);
  const { Step } = Steps;
  const [steps, setSteps] = useState([]);
  const mileStones = localStorage.getItem("milestones");
  const formRef = React.createRef();
  const { TextArea } = Input;

  function handleChangeStep3(value) {
    console.log(`selected ${value}`);
  }

  const prev = () => {
    setCurrent(isCurrent - 1);
  };

  useEffect(() => {
    if (mileStones === 0) {
      message.info("please select milestone");
      history.push("/mileStone");
    } else {
      message.success("milstones " + mileStones);
      let tempArr = [];
      let counter = 0;
      for (let i = 1; i <= mileStones; i++) {
        counter++;
        tempArr.push({
          title: ``,
          content: (
            <Spin spinning={loading} size="large">

              <Row>


                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={2}
                  xs={2}
                  style={{ marginTop: "100px" }}
                ></Col>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={20}
                  xs={20}
                  style={{ marginTop: "100px" }}
                >
                  <Card className="cardStyle mb-3">
                    <Form layout="vertical" onFinish={onFinish} ref={formRef}>
                      <h1 style={{ textAlign: "center" }}>{`Milestone ${i}`}</h1>
                      <Form.Item
                        label="Milestone Name"
                        name="mileStoneName"
                        rules={[
                          { required: true, message: "Milestone name required" },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                      <Form.Item
                        label="Milestone Explaination"
                        name="mileStoneExpalanation"
                        rules={[
                          {
                            required: true,
                            message: "Milestone explaination required",
                          },
                        ]}
                      >
                        <TextArea autoSize />
                        {/* <Input size="large" /> */}
                      </Form.Item>
                      <Form.Item
                        label="Milestone Status"
                        name="mileStoneStatus"
                        rules={[
                          {
                            required: true,
                            message: "Milestone status required",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="Select Status"
                          onChange={handleChangeStep3}
                          size="large"
                          rules={[
                            {
                              required: true,
                              message: "Milestone Status required",
                            },
                          ]}
                        >
                          <Option value="The Milestone has been funded and completed">
                            The Milestone has been funded and completed
                          </Option>
                          <Option value="The Milestone needs to be funded">
                            The Milestone needs to be funded
                          </Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Physical Cash need to complete this Milestone or if already completed the physical cash spent to achieve this milestone"
                        name="mileStonePysicalCash"
                        rules={[
                          { required: true, message: "Physical cash required" },
                        ]}
                      >
                        <InputNumber
                          defaultValue={0}
                          formatter={(value) =>
                            `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          size="large"
                          style={{ width: '100%' }}

                        />
                      </Form.Item>
                      <Form.Item
                        label="Pre-Money Valuation"
                        name="preMoneyValuation"
                        rules={[
                          {
                            required: true,
                            message: "Pre-money valuation required",
                          },
                        ]}
                      >
                        <InputNumber
                          defaultValue={0}
                          formatter={(value) =>
                            `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          size="large"
                          style={{ width: '100%' }}

                        />
                        {/* <Input size="large" /> */}
                      </Form.Item>
                      <Form.Item
                        label="Physical Cash Required"
                        name="physicalCashInvest"
                        rules={[
                          { required: true, message: "Physical cash required" },
                        ]}
                      >
                        <InputNumber
                          defaultValue={0}
                          formatter={(value) =>
                            `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          size="large"
                          style={{ width: '100%' }}

                        />
                      </Form.Item>
                      {counter === 1 && (
                        <Form.Item
                          label="Shares Allocated"
                          name="shareAllocatedPrice"
                          rules={[
                            {
                              required: true,
                              message: "Shares allocated required",
                            },
                          ]}
                        >
                          <InputNumber
                            defaultValue={0}
                            // formatter={(value) =>
                            //   `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            // }
                            size="large"
                            onChange={onChange}
                            style={{ width: '100%' }}

                          />

                          {/* <Input size="large" /> */}
                        </Form.Item>
                      )}
                      <Form.Item>

                        <Row>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="text-center">

                              <Button
                                className="default-btn"
                                size="large"
                                htmlType="submit"
                              >
                                {counter < parseInt(mileStones) ? "Next" : "Done"}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Card>

                </Col>
                <Col
                  xl={4}
                  lg={4}
                  md={4}
                  sm={2}
                  xs={2}
                  style={{ marginTop: "100px" }}
                ></Col>
              </Row>
            </Spin>
          ),
        });
      }
      setSteps(tempArr);
    }
  }, []);

  const onChange = (current) => {
    console.log("onChange:", current);
    // setCurrent(current);
  };

  let anArr = [];
  const onFinish = async (values) => {
    try {
      formRef.current.resetFields();
      anArr.push(values);
      setCurrent((isCurrent) => isCurrent + 1);
      if (anArr.length === parseInt(mileStones)) {
        setLoading(true);
        const response = await apiInstance.post("milestones", {
          mileStones: anArr,
          userId: localStorage.getItem("userId"),
        });
        if (response.data.status) {
          setLoading(false);
          history.push("/table");
        }

        console.log("resetFields", values);
      }
    } catch (error) {
      message.error("Something went wrong, Please try again!");
    }
  };

  return (
    <Spin size="large" spinning={loading}>
      <DashboardHeader />
      {steps.length > 0 && (
        <Row gutter={24}>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={2}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>
          <Col
            xl={16}
            lg={16}
            md={16}
            sm={20}
            xs={20}
            style={{ marginTop: "100px" }}
          >
            <Steps current={isCurrent} onChange={onChange}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[isCurrent]?.content}</div>
            {isCurrent > 0 && (
              <Col span={8} offset={8}>
                {/* <Button className="buttonColor btn-responsive-milestone" size="large" onClick={prev}>
                  Back
                </Button> */}

                {/* <Button
                  className="default-btn btn-responsive-milestone"
                  onClick={() => prev()}
                >
                  Back
                </Button> */}


                <a className="anchor-back" onClick={() => prev()}> Go Back </a>

              </Col>
            )}
          </Col>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={2}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>
        </Row>
      )}
    </Spin>
  );
}
