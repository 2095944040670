import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Auth from '../src/components/auth/login';
import Profile from '../src/components/auth/profile';
import Options from '../src/components/option/options';
import Invest from '../src/components/invest/invest';
import Raise from '../src/components/raise/raise';
import MileStone from '../src/components/milestone/milestone';
import MilestoneForm from '../src/components/milestone/milestone-form';
import Home from '../src/components/Home/homePage';
import InvestmentOpps from '../src/components/InvestmentOpps/investOpps';
import UserAccountPage from '../src/components/Userprofile/userAccountPage';
import InvestSuccess from '../src/components/invest/Success';
import ProfileSuccess from '../src/components/auth/profileSuccess';
import ReadMore from '../src/components/InvestmentOpps/readmore';
import ResetPassword from '../src/components/auth/reset-password';
import VerifyOTP from '../src/components/auth/verify-otp';
import ForgetPassword from '../src/components/auth/forget-password';
import TestForm from '../src/components/milestone/testForm';
import Contact from '../src/components/common/contact';
import Myinvestments from './components/myInvestments/myInvestments';
import Myprojects from './components/myProjects/myProjects';
import Document from '../src/components/milestone/document';
import Table from '../src/components/milestone/table';
import './App.css';
import Blog from './components/blog/blog';


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/contact" component={Contact} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/verify-opt" component={VerifyOTP} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/auth" component={Auth} />
        <Route path="/profile/:id" component={Profile} />
        <Route path="/options" component={Options} />
        <Route path="/invest" component={Invest} />
        <Route path="/raise" component={Raise} />
        <Route path="/mileStone" component={MileStone} />
        <Route path="/milestoneform" component={MilestoneForm} />
        <Route path="/investopps" component={InvestmentOpps} />
        <Route path="/test" component={TestForm} />
        <Route path="/useraccountpage" component={UserAccountPage} />
        <Route path="/investsuccess" component={InvestSuccess} />
        <Route path="/profilesuccess" component={ProfileSuccess} />
        <Route path="/readmore" component={ReadMore} />
        <Route path="/myprojects" component={Myprojects} />
        <Route path="/myinvestments" component={Myinvestments} />
        <Route path="/document" component={Document} />
        <Route path="/table" component={Table} />
        <Route path='/research' component={Blog} />
      </Switch>
    </BrowserRouter>
  );
}
export default App;

