import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Steps,
  Typography,
  Spin,
  InputNumber,
  Select,
  Radio,
  Space,
  Divider,
  message,
} from "antd";
import Selectr from "react-select";
import countryList from "react-select-country-list";
import { useHistory } from "react-router-dom";
import { apiInstance } from "../../config/axios.config";
import "../../App.css";
import "../../styles/all.css";
import "../../styles/style.css";
import DashboardHeader from "../common/header";

export default function Raise() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [radioValue, setRadioValue] = useState(0);
  const [selectOne, setSelectOne] = useState("");
  const [selectTwo, setSelectTwo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [showInputOther, setInputOthers] = useState(false);
  const [showInput, setInput] = useState(false);
  const [showProject, setProject] = useState(false);
  const { Step } = Steps;
  const { Option } = Select;
  const [value, setValue] = useState("Project");
  const [valueRadioButton, setvalueRadioButton] = useState("Yes");

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function handleChange(value) {
    if (value === "Others") {
      setInputOthers(true);
      setInput(false);
    } else {
      setInputOthers(false);
      setInput(true);
    }

    console.log(`selected ${value}`);
    setSelectOne(value);
  }

  function onChangeStep2(e) {
    console.log(e.target.value);
    setvalueRadioButton(e.target.value);
  }

  function handleChangeStep3(value) {
    console.log(`selected ${value}`);
    setSelectTwo(value);
    if (value === "Others") {
      setProject(true);
    } else {
      setProject(false);
    }
  }

  const onFinish = (values) => {
    console.log(values);
    setCompanyName(values.companyName);
    setCompanyLocation(values.companyLocation);
    setCurrent(current + 1);
  };

  const onFinishSteps = async (values) => {
    try {
      console.log();
      const response = await apiInstance.post("project", {
        companyName: companyName,
        companyLocation: companyLocation,
        raiseFunds: radioValue,
        sector: selectOne,
        projectStage: selectTwo,
        amount: values.amount,
        companyWebsite: values.companyWebsite,
        additionalInformation: values.additionalInfo,
        userId: localStorage.getItem('userId'),
      });

      console.log(response.data);

      const { status } = response.data;
      if (status) {
        message.success("project created successfully!");
        history.push("/document");
      }
    } catch (error) {
      message.error("something went wrong!");
    }
  };

  const onFinishFoo = (values) => {
    setCurrent(current + 1);
  }

  const steps = [
    {
      title: "Project/Company Registration",
      content: (
        <Card
          className="cardStyle mb-3"
          style={{ marginTop: "30px" }}
        >
          <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Want to raise funds for:
          </h3>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio
                value="Project"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              >
                Project
              </Radio>
              <Radio
                value="Company"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              >
                Company
              </Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Form onFinish={onFinish} name="basic" layout="vertical">
            <Form.Item
              label="Type your Project/Company Name:"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Company name required!",
                },
              ]}>
              <Input
                size="large"
                placeholder="Project or Company Name"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}

              />
            </Form.Item>
            <Form.Item
              label="Type your Project/Company Location:"
              name="companyLocation"
              rules={[
                {
                  required: true,
                  message: "Company location required!",
                },
              ]}>
              <Input
                placeholder="Project or Company Location"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="large"

              />
            </Form.Item>
            <Form.Item>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="text-center">
                    <Button
                      className="default-btn"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      title: "Sector & Milestones Information",
      content: (

        <Card
          className="cardStyle mb-3"
          style={{ marginTop: "30px" }}
        >

          <Form layout="vertical" onFinish={onFinishFoo}>
            <Form.Item label=" Choose the Sector:" name="choose" rules={[{ required: true, message: "this field is required", }]}>
              <Select
                defaultValue="Sector"
                onChange={handleChange}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
                required
                title="This field should not be left blank."
              >
                <Option value="Energy Industries">Energy Industries</Option>
                <Option value="Energy Demand">Energy Demand</Option>
                <Option value="Energy Distribution">Energy Distribution</Option>
                <Option value="Manufacturing Industries">
                  Manufacturing Industries
                </Option>
                <Option value="Chemical Industries">Chemical Industries</Option>
                <Option value="Transport">Transport</Option>
                <Option value="Mining/Mineral Production">
                  Mining/Mineral Production
                </Option>
                <Option value="Fugitive Emission">Fugitive Emission </Option>
                <Option value="Solvent Use">Solvent Use</Option>
                <Option value="Waste Handling and disposal">
                  Waste Handling and disposal
                </Option>
                <Option value="Afforestation and reforestation">
                  Afforestation and reforestation
                </Option>
                <Option value="Agriculture">Agriculture</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>



            {showInputOther && (
              <Row>
                <Divider />
                <Form.Item
                  name="Company"
                  label="Please tell us about the sector of Project/Company"
                  rules={[{ required: true, message: "this field is required", }]}
                >
                  <Input
                    placeholder="Sector of Project/Company"
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    size="large"

                  />
                </Form.Item>
              </Row>
            )}

            {
              showInput && (
                <Row>
                  <Divider />
                  <Form.Item
                    label="Please tell us about the sub-sector of Project/Company"
                    name="Project"
                    rules={[{ required: true, message: "this field is required", }]}>
                    <Input
                      placeholder="Sub-sector of Project/Company"
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      size="large"

                    />
                  </Form.Item>
                </Row>
              )
            }

            <Divider />
            <Form.Item label="Choose the Project Stage/Milestone:" name="another" rules={[{ required: true, message: "this field is required", }]}>
              <Select
                defaultValue="Project Stage/Milestone"
                onChange={handleChangeStep3}
                size="large"
                style={{ width: '100%' }}
                className="text-grey"
                required
              >
                <Option value="Pre-Construction Development">
                  {" "}
                  Pre-Construction Development
                </Option>
                <Option value="SPV set up"> SPV set up</Option>
                <Option value="Land lease"> Land lease</Option>
                <Option value="Indicative Study & Impact Study">
                  {" "}
                  Indicative Study & Impact Study
                </Option>
                <Option value="Environmental Impact Assessment">
                  {" "}
                  Environmental Impact Assessment
                </Option>
                <Option value="Project Design and Planning">
                  {" "}
                  Project Design and Planning
                </Option>
                <Option value="Planning Consent Granted">
                  {" "}
                  Planning Consent Granted
                </Option>
                <Option value="Project Design Document Completed">
                  {" "}
                  Project Design Document Completed
                </Option>
                <Option value="Validation with UNFCCC & Registration with UNFCCC">
                  {" "}
                  Validation with UNFCCC & Registration with UNFCCC
                </Option>
                <Option value="Power Purchase Agreement">
                  {" "}
                  Power Purchase Agreement
                </Option>
                <Option value="Construction Permit Completed">
                  {" "}
                  Construction Permit Completed
                </Option>
                <Option value=" Term Sheet Agreed for onward sale">
                  {" "}
                  Term Sheet Agreed for onward sale
                </Option>
                <Option value="Share Purchase Agreement Executed">
                  {" "}
                  Share Purchase Agreement Executed
                </Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
            <Divider />


            {
              showProject && (
                <Row>
                  <Form.Item
                    label="Please tell us about the Project Stage/Milestone of your
                      Project/Company"
                    name="stage"
                    rules={[{ required: true, message: "this field is required", }]}>
                    <Input
                      placeholder="Project Stage/Milestone of your Project/Company"
                      size="large"
                    />
                  </Form.Item>
                </Row>
              )
            }

            <Form.Item label="Does your project reduce emissions below the base case scenario:" name="">
              <Radio.Group onChange={onChangeStep2} value={valueRadioButton}>
                <Space direction="horizontal">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Space>
              </Radio.Group>
              <Divider />
            </Form.Item>
            <Divider />
            <Row span={24}>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="text-center">
                  <Button
                    className="default-btn"
                    onClick={() => prev()}
                  >
                    Back
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    className="default-btn"
                    htmlType="submit"
                  // onClick={() => next()}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          {/* <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Choose the Sector:
          </h3> */}
          {/* 
          <Select
            defaultValue="Sector"
            onChange={handleChange}
            size="large"
            className="text-grey"
            style={{ width: '100%' }}
            required
            title="This field should not be left blank."
          >
            <Option value="Energy Industries">Energy Industries</Option>
            <Option value="Energy Demand">Energy Demand</Option>
            <Option value="Energy Distribution">Energy Distribution</Option>
            <Option value="Manufacturing Industries">
              Manufacturing Industries
            </Option>
            <Option value="Chemical Industries">Chemical Industries</Option>
            <Option value="Transport">Transport</Option>
            <Option value="Mining/Mineral Production">
              Mining/Mineral Production
            </Option>
            <Option value="Fugitive Emission">Fugitive Emission </Option>
            <Option value="Solvent Use">Solvent Use</Option>
            <Option value="Waste Handling and disposal">
              Waste Handling and disposal
            </Option>
            <Option value="Afforestation and reforestation">
              Afforestation and reforestation
            </Option>
            <Option value="Agriculture">Agriculture</Option>
            <Option value="Others">Others</Option>
          </Select> */}
          {/* 
          {showInputOther && (
            <Row>
              <Divider />
              <Col>
                <Form layout="vertical" name="basic">
                  <Form.Item
                    name="Company"
                    label="Please tell us about the sector of Project/Company"
                    rules={[
                      {
                        required: true,
                        message: "Pre-money valuation required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Sector of Project/Company"
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      size="large"

                    />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )} */}

          {/* {
            showInput && (
              <Row>
                <Divider />
                <Col>
                  <Form layout="vertical">
                    <Form.Item
                      label="Please tell us about the sub-sector of Project/Company"
                      name="Project"
                      rules={[
                        {
                          required: true,
                          message: "Pre-money valuation required",
                        },
                      ]}>
                      <Input
                        placeholder="Sub-sector of Project/Company"
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        size="large"

                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            )
          } */}

          {/* <Divider />
          <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Choose the Project Stage/Milestone:
          </h3>

          <Select
            defaultValue="Project Stage/Milestone"
            onChange={handleChangeStep3}
            size="large"
            style={{ width: '100%' }}
            className="text-grey"
            required
          >
            <Option value="Pre-Construction Development">
              {" "}
              Pre-Construction Development
            </Option>
            <Option value="SPV set up"> SPV set up</Option>
            <Option value="Land lease"> Land lease</Option>
            <Option value="Indicative Study & Impact Study">
              {" "}
              Indicative Study & Impact Study
            </Option>
            <Option value="Environmental Impact Assessment">
              {" "}
              Environmental Impact Assessment
            </Option>
            <Option value="Project Design and Planning">
              {" "}
              Project Design and Planning
            </Option>
            <Option value="Planning Consent Granted">
              {" "}
              Planning Consent Granted
            </Option>
            <Option value="Project Design Document Completed">
              {" "}
              Project Design Document Completed
            </Option>
            <Option value="Validation with UNFCCC & Registration with UNFCCC">
              {" "}
              Validation with UNFCCC & Registration with UNFCCC
            </Option>
            <Option value="Power Purchase Agreement">
              {" "}
              Power Purchase Agreement
            </Option>
            <Option value="Construction Permit Completed">
              {" "}
              Construction Permit Completed
            </Option>
            <Option value=" Term Sheet Agreed for onward sale">
              {" "}
              Term Sheet Agreed for onward sale
            </Option>
            <Option value="Share Purchase Agreement Executed">
              {" "}
              Share Purchase Agreement Executed
            </Option>
            <Option value="Others">Others</Option>
          </Select>

          <Divider /> */}
          {/* {
            showProject && (
              <Row>
                <Col>
                  <Form layout="vertical">
                    <Form.Item
                      label="Please tell us about the Project Stage/Milestone of your
                      Project/Company"
                      name="stage"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}>
                      <Input
                        placeholder="Project Stage/Milestone of your Project/Company"
                        size="large"

                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            )
          } */}

          {/* <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Does your project reduce emissions below the base case scenario:
          </h3>
          <Radio.Group onChange={onChangeStep2} value={valueRadioButton}>
            <Space direction="horizontal">
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Row span={24}>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="text-center">
                <Button
                  className="default-btn"
                  onClick={() => prev()}
                >
                  Back
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={() => next()}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row> */}
        </Card >
      ),
    },
    {
      title: "Amount to be Raised",
      content: (
        <Card
          className="cardStyle mb-3"
          style={{ marginTop: "30px" }}
        >
          <Form layout="vertical" onFinish={onFinishSteps}>
            <Form.Item
              name="amount"
              label="Type the Amount you want to Raise:"
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                size="large"
                className="text-grey"
                style={{ width: "100%" }}

              />
            </Form.Item>
            <Form.Item name="companyWebsite" label="Type your Company Website:" rules={[{
              required: true,
              message: "this field is required",
            }]}>
              <Input
                addonBefore="https://"
                addonAfter=".com"
                defaultValue="mysite"
                placeholder="Company Website"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="large"

              />
            </Form.Item>
            <Form.Item name="additionalInfo" label="Type in the link to the LinkedIn profile of company's/Project's
                Managing Director:" rules={[{
                required: true,
                message: "this field is required",
              }]}>
              <Input
                placeholder="Link to the Linkedin profile"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                size="large"

              />
            </Form.Item>
            <Form.Item>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  {/* <Button type="primary" htmlType="submit" block className="buttonColor" onClick={() => prev()} style={{ marginTop: "20px" }}>
                            Back
                        </Button> */}
                  <div className="text-center">


                    <Button
                      className="default-btn"
                      htmlType="submit"
                      onClick={() => prev()}
                    >
                      Back
                    </Button>

                    &nbsp; &nbsp;
                    {/* <Button type="primary" htmlType="submit" block className="buttonColor" onClick={() => next()} style={{ marginTop: "20px" }}>
                            NEXT
                        </Button> */}
                    <Button
                      className="default-btn"
                      htmlType="submit"
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      <DashboardHeader />
      {/* <header id="header">
                <div class="container-fluid">
                    <div class="header-wrap clearfix">
                        <div class="header-left">
                            <div class="logo">
                                <a href="/"></a>
                            </div>
                        </div>
                        <div class="header-right">
                            <div class="top-contactdetail">
                                <ul>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="callto:0800 000 1234">CALL US ON 0800 000 1234</a></li>
                                </ul>
                            </div>
                            <div class="menu">
                                <ul>
                                    <li><a onClick={investPage}>invest</a></li>
                                    <li><a onClick={raisePage}>raise</a></li>
                                    <li><a href="https://demec9.com/blog/">Research</a></li>
                                    <li><a>contact</a></li>
                                    <li><a onClick={signUp}>Sign Up</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}

      <Row>
        <Col
          xl={4}
          lg={4}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
        <Col
          xl={16}
          lg={16}
          md={16}
          sm={20}
          xs={20}
          style={{ marginTop: "100px" }}
        >
          <h1>Project/Company Registration</h1>
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
      </Row>
      <Row>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
        <Col
          xl={8}
          lg={8}
          md={16}
          sm={20}
          xs={20}
          style={{ marginTop: "100px" }}
        >

          <Card className="mb-3" style={{ border: 0 }}>
            <div className="steps-content">{steps[current].content}</div>
          </Card>

          {/* <div className="steps-action">
                        {current < steps.length - 1 && (
                            <Button type="primary" onClick={() => next()}>
                                Next
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={() => message.success('Processing complete!')}>
                                Done
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Previous
                            </Button>
                        )}
                    </div> */}
        </Col>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
      </Row>
    </Spin>
  );
}
