import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Steps,
  DatePicker,
  message,
  Spin,
  Select,
  InputNumber,
} from "antd";
import Selectr from "react-select";
import countryList from "react-select-country-list";
import { useHistory, useParams } from "react-router-dom";
import { apiInstance } from "../../config/axios.config";
import { MailOutlined } from "@ant-design/icons";
import DashboardHeader from "../common/header";
import moment from "moment";

export default function Profile({ match }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const { Step } = Steps;
  const { Option } = Select;
  const { TextArea } = Input;
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [date, setDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [engValue, setEngValue] = useState("");
  const [engField, setEngField] = useState(false);
  const [otherField, setOthersField] = useState(false);
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YYYY"];

  const changeHandler = (value) => {
    setValue(value);
  };

  const onFinishName = (values) => {
    console.log(values);
    setFirstName(values.firstName);
    setLastName(values.lastName);
    setCurrent(current + 1);
  };

  const onFinishAddress = async (values) => {
    try {
      setLoading(true);
      console.log(values);
      setCountry(values.country.label);
      setAddress(values.address);
      console.log(country, address);
      const id = match.params.id;
      localStorage.setItem("userId", id);
      const user = await apiInstance.post("user", { userId: id });
      if (user) localStorage.setItem("email", user.data.data.email);
      const response = await apiInstance.put("user/profile", {
        id: id,
        firstName: firstName,
        lastName: lastName,
        country: values.country.label,
        gender: gender,
        date: date,
        phoneNumber: values.phoneNumber,
      });

      if (response.data.status) {
        setLoading(false);
        history.push("/profilesuccess");
      } else {
        setLoading(false);
        message.error("something went wrong, please try again!");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const dateHandler = (value) => {
    setDate(moment(value._d).format(moment.HTML5_FMT.DATE));
    console.log(value._d, moment(value._d).format(moment.HTML5_FMT.DATE));
  };

  const steps = [
    {
      title: "Name",
      content: (
        <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinishName}
            formLayout="vertical"
          >
            <h3 style={{ marginBottom: "10px" }}>Type your firstname:</h3>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input placeholder="FIRST NAME" size="large" />
            </Form.Item>
            <h3 style={{ marginBottom: "10px" }}>Type your lastname:</h3>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input placeholder="LAST NAME" size="large" />
            </Form.Item>

            <Form.Item>
              <div className="text-center">
                <Button
                  type="primary"
                  className="default-btn"
                  htmlType="submit"
                >
                  NEXT
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      title: "DOB & Gender",
      content: (
        <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
          <Form name="basic" formLayout="vertical">
            <h3 style={{ marginBottom: "10px" }}>Select Date of Birth:</h3>
            <Form.Item
              name="dob"
              rules={[{ required: true, message: "Please input your dob!" }]}
            >
              {/* <DatePicker size="large" style={{ width: "410px" }} selected={startDate} required /> */}
              <DatePicker
                size="large"
                defaultValue={moment("01/01/1990", dateFormatList[0])}
                format={dateFormatList}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
                onChange={dateHandler}
              />
            </Form.Item>
            <h3 style={{ marginBottom: "10px" }}>Select Your Gender:</h3>
            <Form.Item
              name="gender"
              rules={[
                { required: true, message: "Please select your gender!" },
              ]}
            >
              <Select
                placeholder="Please select your gender!"
                onChange={handleChange}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="Prefer not to say"> Prefer not to say </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="text-center">
                    <Button
                      type="primary"
                      className="default-btn"
                      onClick={() => {
                        setCurrent(current - 1);
                      }}
                    >
                      Back
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      type="primary"
                      className="default-btn"
                      onClick={() => next()}
                      htmlType="submit"
                    >
                      NEXT
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
    {
      title: "Contact",
      content: (
        <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
          <Form onFinish={onFinishAddress} formLayout="vertical">
            <h3 style={{ marginBottom: "10px" }}>Enter your phone number:</h3>
            <Form.Item
              name="phoneNumber"
              rules={[
                { required: true, message: "Please input your phone number" },
              ]}
            >
              <InputNumber
                placeholder="Phone number"
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <h3 style={{ marginBottom: "10px" }}>Select your country:</h3>
            <Form.Item
              name="country"
              rules={[
                { required: true, message: "Please input your country!" },
              ]}
            >
              <Selectr
                options={options}
                value={value}
                onChange={changeHandler}
              />
            </Form.Item>
            <h3>Profession</h3>
            <Form.Item>
              <Select
                placeholder="Please select your Profession!"
                onChange={handleChangeProfession}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
              >
                <Option value="Chief Executive, Senior Official or Legislators">
                  Chief Executive, Senior Official or Legislators
                </Option>
                <Option value="Business Services or Administration Manager">
                  Business Services or Administration Manager
                </Option>
                <Option value="Sales, Marketing & Development Manager">
                  {" "}
                  Sales, Marketing & Development Manager{" "}
                </Option>
                <Option value="Production manager in agriculture, forestry, and fisheries">
                  Production manager in agriculture, forestry, and fisheries{" "}
                </Option>
                <Option value="Manufacturing, mining, construction, and distribution manager">
                  Manufacturing, mining, construction, and distribution manager
                </Option>
                <Option value="Information and communications technology service manager">
                  Information and communications technology service manager
                </Option>
                <Option value="Professional services manager">
                  Professional services manager
                </Option>
                <Option value="Science Professional">
                  Science Professional
                </Option>
                <Option value="Engineering Professional">
                  Engineering Professional
                </Option>
                <Option value="Health Professional">Health Professional</Option>
                <Option value="Teaching Professional">
                  Teaching Professional
                </Option>
                <Option value="IT Professional">IT Professional</Option>
                <Option value="Finance professionals">
                  {" "}
                  Finance professionals
                </Option>
                <Option value="Administration professionals">
                  {" "}
                  Administration professionals
                </Option>
                <Option value="Sales, marketing, and public relations professionals">
                  Sales, marketing, and public relations professionals
                </Option>
                <Option value="Legal, Social & Culture Professional">
                  Legal, Social & Culture Professional
                </Option>
                <Option value="Armed Forces Occupation">
                  Armed Forces Occupation
                </Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>

            {engField && (
              <Form.Item label="Please select your Engineering Field">
                <Select
                  placeholder="Please select your ProfessionProfession!"
                  onChange={handleChangeEng}
                  size="large"
                  className="text-grey"
                  style={{ width: '100%' }}
                >
                  <Option value="Electrical Engineer">
                    Electrical Engineer
                  </Option>
                  <Option value="Mechanical Engineer">
                    Mechanical Engineer
                  </Option>
                  <Option value="Telecommunications Engineer">
                    Telecommunications Engineer
                  </Option>
                  <Option value="Civil & Structural Engineer">
                    Civil & Structural Engineer
                  </Option>
                  <Option value="Electronics Engineer">
                    Electronics Engineer
                  </Option>
                  <Option value="Manufacturing Engineer">
                    Manufacturing Engineer
                  </Option>
                  <Option value="Industrial Engineer">
                    Industrial Engineer
                  </Option>
                  <Option value="Network Engineer">Network Engineer</Option>
                  <Option value="IT Engineer">IT Engineer</Option>
                  <Option value="Chemical Engineer">Chemical Engineer</Option>
                  <Option value="Computer Engineer">Computer Engineer</Option>
                  <Option value="Environmental Engineer">
                    Environmental Engineer
                  </Option>
                </Select>
              </Form.Item>
            )}

            {otherField && (
              <Form.Item name="profession">
                <Input
                  placeholder="Please type in your Profession"
                  size="large"
                  className="text-grey"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}

            <Form.Item>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="text-center">
                    <Button
                      type="primary"
                      className="default-btn"
                      onClick={() => {
                        setCurrent(current - 1);
                      }}
                    >
                      Back
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      type="primary"
                      className="default-btn"
                      htmlType="submit"
                    >
                      Done
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function handleChange(value) {
    setGender(value);
    console.log(`selected ${value}`);
  }

  function handleChangeProfession(value) {
    if (value === "Engineering Professional") {
      setEngField(true);
      setOthersField(false);
    }

    if (value === "Others") {
      setEngField(false);
      setOthersField(true);
    }
    console.log(`selected ${value}`);
  }

  function handleChangeEng(value) {
    setEngValue(value);
    console.log(`selected ${value}`);
  }

  const signUp = () => {
    history.push("/auth");
  };

  const investOppspage = () => {
    history.push("/investopps");
  };

  const gotoContact = () => {
    history.push("/contact");
  };

  return (
    <Spin size="large" spinning={loading}>
      <DashboardHeader />
      <Row>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>

        <Col
          xl={8}
          lg={8}
          md={16}
          sm={20}
          xs={20}
          style={{ marginTop: "100px" }}
        >
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
        </Col>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
      </Row>
    </Spin>
  );
}
