import React, { useState } from "react";
import ReadMoreReact from "read-more-react";

import "../../components/InvestmentOpps/ReadMore.css";
import "../../styles/all.css";
import "../../styles/style.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


import { useHistory } from "react-router-dom";

import {
  Typography,
  Row,
  Col,
  Card,
  Progress,
  Button,
  Modal,
  Spin,
  Input,
  message,
  Form,
  Divider,
  InputNumber,
  Anchor,
} from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { apiInstance } from "../../config/axios.config";

import prjimg from "../../images/sadprojimg.jpeg";
import { MailOutlined } from "@ant-design/icons";
import DashboardHeader from "../common/header";
import storeElectric from "../../images/store-electric.jpeg";
import demeclogo from "../../images/logo.png";


export default function InvestOpps() {
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [isModalVisible5, setIsModalVisible5] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);
  const [value5, setValue5] = useState(0);
  const history = useHistory();
  const [responsive, setResponsive] = useState({
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  });

  const extraContent = (
    <div>
      <p className="extra-content-readmore" className="text-grey">
        This is on the request of one of the world’s leading soft drink
        manufacturers who has worked closely in the past with one of the core
        team founders of MHK. Accordingly, MHK has the remit to design and
        launch Moovit (vitamin infused milk drink) & Chocovit (vitamin infused
        chocolate bars) and Nibblevit (vitamin infused seed bars).
      </p>
    </div>
  );
  const linkName = readMore ? "Read Less << " : "Read More >> ";
  const { Meta } = Card;
  const { Title, Text } = Typography;

  // 3
  const showModal3 = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setIsModalVisible3(true);
      console.log("hit in show modal");
    } else {
      history.push('/auth');
    }
  };

  const handleOk3 = async () => {
    try {
      setLoading(true);
      const email = localStorage.getItem("email");
      const response = await apiInstance.post("user/pledge/email", {
        amount: value3,
        milestone: "Milestone 03",
        email: email,
        userId: localStorage.getItem("userId"),
      });
      if (response.data.status) {
        setValue3(0);
        message.success({
          content:
            "Your pledge has been received. Please check your registered email for the confirmation",
          style: { marginTop: "20vh" },
        });
        setIsModalVisible3(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error({
        content: "Something went wrong, please try again!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };
  // 3 end here ...

  const showModal4 = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setIsModalVisible4(true);
      console.log("hit in show modal");
    } else {
      history.push('/auth');
    }
  };

  const handleOk4 = async () => {
    try {
      setLoading(true);
      const email = localStorage.getItem("email");
      const response = await apiInstance.post("user/pledge/email", {
        amount: value4,
        milestone: "Milestone 04",
        email: email,
        userId: localStorage.getItem("userId"),
      });
      if (response.data.status) {
        setValue4(0);
        message.success({
          content: "Email sent successfully!",
          style: { marginTop: "20vh" },
        });
        setIsModalVisible4(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error({
        content: "Something went wrong, please try again!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };

  // 4 end here ...

  const showModal5 = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setIsModalVisible4(true);
      console.log("hit in show modal");
    } else {
      history.push('/auth');
    }
  };

  const handleOk5 = async () => {
    try {
      setLoading(true);
      const email = localStorage.getItem("email");
      const response = await apiInstance.post("user/pledge/email", {
        amount: value5,
        milestone: "Milestone 05",
        email: email,
        userId: localStorage.getItem("userId"),
      });
      if (response.data.status) {
        setValue5(0);
        setIsModalVisible5(false);
        setLoading(false);
        message.success({
          content: "Email sent successfully!",
          style: { marginTop: "20vh" },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error({
        content: "Something went wrong, please try again!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const handleCancel5 = () => {
    setIsModalVisible5(false);
  };

  // 4 end here ...

  function onChange(value) {
    console.log("changed", value);
    setValue3(value);
  }

  function onChange4(value) {
    console.log("changed", value);
    setValue4(value);
  }

  function onChange5(value) {
    console.log("changed", value);
    setValue5(value);
  }

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: 4,
    backgroundColor: "#1088e9",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
  };

  return (
    <>
      <Modal
        title="How much you want to pledge ?"
        visible={isModalVisible3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        style={{ marginTop: "100px" }}
      >
        <Spin size="large" spinning={loading}>
          <Form name="basic" formLayout="vertical">
            <Form.Item>
              <InputNumber
                placeholder="Enter amount"
                size="large"
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={onChange}
                style={{ width: "200px" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title="How much you want to pledge ?"
        visible={isModalVisible4}
        onOk={handleOk4}
        onCancel={handleCancel4}
        style={{ marginTop: "100px" }}
      >
        <Spin size="large" spinning={loading}>
          <Form name="basic" formLayout="vertical">
            <Form.Item>
              <InputNumber
                placeholder="Enter amount"
                size="large"
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={onChange4}
                style={{ width: "200px" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <Modal
        title="How much you want to pledge ?"
        visible={isModalVisible5}
        onOk={handleOk5}
        onCancel={handleCancel5}
        style={{ marginTop: "100px" }}
      >
        <Spin size="large" spinning={loading}>
          <Form name="basic" formLayout="vertical">
            <Form.Item>
              <InputNumber
                placeholder="Enter amount"
                size="large"
                formatter={(value) =>
                  `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={onChange5}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>

      <DashboardHeader />

      <section id="currentproject">
        <div class="container-fluid">
          <div class="project-section">
            <Row>
              <Col xl={7} lg={6} md={24} sm={24} xs={24}>
                <Card className="investleftcard" cover={<img alt="example" src={prjimg} />}>
                  {/* <div className="cardbackOpportunities">
                                    </div> */}
                  <Meta
                    style={{ padding: "15px", textAlign: "center" }}
                    title="My Healthy Kick"
                  />
                  <Row
                    span={24}
                    style={{ padding: "0px", textAlign: "center" }}
                  >
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Raised
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>£ 292,000 </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Milestones
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>12</Text>
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{ paddingTop: "12px", textAlign: "center" }}
                  >
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Sector
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>Food</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Location
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>UK</Text>
                    </Col>
                  </Row>
                  {/* <ReadMoreReact
                                        style={{ padding: "13px" }}
                                        text={"MHK is developing a range of healthy, tasty, environmentally conscientious vitamin infused drinks and bars. The commercialisation of the above will then be followed by the development and launch of a range of vitamin infused vegetarian meals. This is on the request of one of the world’s leading soft drink manufacturers who has worked closely in the past  with one of the core team founders of MHK. Accordingly,MHK has the remit to design and launch Moovit (vitamin infused milk drink) & Chocovit (vitamin infused chocolate bars) and Nibblevit (vitamin infused seed bars)."}

                                        readMoreText="Read More" /> */}
                  <div
                    style={{
                      height: "90px",
                      width: "100%",
                      padding: "13px",
                      color: "#696969",
                      textAlign: "center",
                      marginBottom: "180px",
                    }}
                  >
                    MHK is developing a range of healthy, tasty, environmentally
                    conscientious vitamin infused drinks and bars. The
                    commercialisation of the above will then be followed by the
                    development and launch of a range of vitamin infused
                    vegetarian meals. This is on the request of one of the
                    world’s leading soft drink manufacturers who has worked
                    closely in the past with one of the core team founders of
                    MHK.
                  </div>
                </Card>
              </Col>
              <Col xl={17} lg={18} md={24} sm={24} xs={24}>
                <OwlCarousel
                  className="owl-theme"
                  loop={true}
                  margin={8}
                  nav={true}
                  item={3}
                  nav
                  responsive={responsive}
                >
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 01
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Founder and Idea Development"
                    // description="The inspiration for the company, the product and its potential, plus company formation and early development."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 150,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 150,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>750,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.20</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      The inspiration for the company, the product and its
                      potential, plus company formation and early development.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 02
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Brand Formation & Development"
                    //description="Very early investment from business professionals across fields such as finance, graphic design, technology, marketing, administration, banking etc, that will also be working in the business. In this case, participants buy in and MHK's headcount has increased from 3 to circa 11. Marketing, Brand and Product Further Developed."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 50,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 312,500</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 50,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>120,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.42</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Very early investment from business professionals across
                      fields such as finance, graphic design, technology
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              // onClick={showModal3}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 03
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="LOI with Flavours Company"
                    // description="Industry Partners Join - Please Note Soft Equity Arrangement."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 45,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 1,500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 45,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>25,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 1.68</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Industry Partners Join - Please Note Soft Equity
                      Arrangement.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              onClick={showModal4}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              pledge
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 04
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Incubation Round"
                    // description="Early Funding Round For Cornerstone Investors."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={47.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={47.0}
                          format={() => "£ 47,000"}
                        />
                      </Col>
                    </Row>

                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 100,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>44,750</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2.23</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Early Funding Round For Cornerstone Investors.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}

                          <div className="text-center">
                            <Button
                              onClick={showModal5}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              pledge
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 05
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="LOI from Major Distributor / Agent"
                    // description="Letter of Intent from Major Distributor - Further Details to be made Available."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,400,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 100,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>39,156</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2.55</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Letter of Intent from Major Distributor - Further Details
                      to be made Available.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 06
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="LOI from Manufacturer"
                    // description="Letter of Intent from Manufacturer, who will cash-flow manufacture."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,600,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 100,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>37,650</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2.66</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Letter of Intent from Manufacturer, who will cash-flow
                      manufacture.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 07
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Crowdfunding / Third Party Raises"
                    // description="Crowdfunding Opens With Support from Industry Contacts and Cornerstone Investors,"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 3,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 1,200,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>40,6623</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2.95</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Crowdfunding Opens With Support from Industry Contacts and
                      Cornerstone Investors.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 08
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Product Fully Developed and Tested"
                    // description="Product Now Fully Developer and Tested With 1.2M Cash In Bank"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 10,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ -</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> - </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 7.03</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Product Now Fully Developer and Tested With 1.2M Cash In
                      Bank.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 09
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Product Launch and Rolled Out Globally"
                    // description="Product Launch. Based on Successful Launch and Inventive Marketing (think Mini Car), additional Distributors Join and cover 40% of distribution market"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 30,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ -</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> - </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 21.08</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Product Launch. Based on Successful Launch and Inventive
                      Marketing (think Mini Car).
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}

                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 10
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Manufacturer Investment"
                    // description="The Manufacturer Now Further Realises Potential of Product and as They Are Cashflowing Manufacture Acquire a Shareholding."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 60,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 8,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>18,9757</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 37.20</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      The Manufacturer Now Further Realises Potential of Product
                      and as They Are Cashflowing.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 11
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Product Launch Globally"
                    // description="Marketing, Promotion and Distribution Further Accelerates across drinks, bars, ice cream."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 80,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ -</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> - </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 49.60</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Marketing, Promotion and Distribution Further Accelerates
                      across drinks, bars, ice cream.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 12
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Sale to Global Brand Drinks Company"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "£ 0"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 100,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ -</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> - </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 62.00</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      MHK Drinks, Bars, and Ice Cream carved out into subsiduary
                      and invite bids from FMCG Majors.
                    </div>
                  </Card>
                </OwlCarousel>
              </Col>
            </Row>
          </div>
        </div>
      </section>


















































      <section id="currentproject">
        <div class="container-fluid">
          <div class="project-section">
            <Row>
              <Col xl={7} lg={6} md={24} sm={24} xs={24}>
                <Card className="investleftcard" cover={<img alt="example" src={storeElectric} />}>
                  {/* <div className="cardbackOpportunities">
                                    </div> */}
                  <Meta
                    style={{ padding: "15px", textAlign: "center" }}
                    title="Storelectric"
                  />
                  <Row
                    span={24}
                    style={{ padding: "0px", textAlign: "center" }}
                  >
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Raised
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>£ 69,698,975 </Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Milestones
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>21</Text>
                    </Col>
                  </Row>
                  <Row
                    span={24}
                    style={{ paddingTop: "12px", textAlign: "center" }}
                  >
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Sector
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>Energy</Text>
                    </Col>
                    <Col span={12} style={{ textAlign: "center" }}>
                      <Text style={{ fontSize: "15px", color: "gray" }}>
                        Location
                      </Text>
                      <br></br>
                      <Text style={{ fontSize: "20px" }}>UK</Text>
                    </Col>
                  </Row>
                  {/* <ReadMoreReact
                                        style={{ padding: "13px" }}
                                        text={"MHK is developing a range of healthy, tasty, environmentally conscientious vitamin infused drinks and bars. The commercialisation of the above will then be followed by the development and launch of a range of vitamin infused vegetarian meals. This is on the request of one of the world’s leading soft drink manufacturers who has worked closely in the past  with one of the core team founders of MHK. Accordingly,MHK has the remit to design and launch Moovit (vitamin infused milk drink) & Chocovit (vitamin infused chocolate bars) and Nibblevit (vitamin infused seed bars)."}

                                        readMoreText="Read More" /> */}
                  <div
                    style={{
                      height: "90px",
                      width: "100%",
                      padding: "13px",
                      color: "#696969",
                      textAlign: "center",
                      marginBottom: "180px",
                    }}
                  >
                    Storelectric Ltd is a developer of projects using highly efficient forms of Compressed Air Energy Storage (CAES) using existing technologies, at a grid scale, efficiently and cost-effectively.
                  </div>
                </Card>
              </Col>
              <Col xl={17} lg={18} md={24} sm={24} xs={24}>
                <OwlCarousel
                  className="owl-theme"
                  loop={true}
                  margin={8}
                  nav={true}
                  item={3}
                  nav
                  responsive={responsive}
                >
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 01
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Founders and Idea Development"
                    // description="The inspiration for the company, the product and its potential, plus company formation and early development."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "50,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>---</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 50,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>9,500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.01</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      The inspiration for the company, the product and its potential, plus company formation and early development.
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 02
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Storelectric Wins Shell Springboard for Utility Scale Energy Storage Solutions"
                    //description="Very early investment from business professionals across fields such as finance, graphic design, technology, marketing, administration, banking etc, that will also be working in the business. In this case, participants buy in and MHK's headcount has increased from 3 to circa 11. Marketing, Brand and Product Further Developed."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "20,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> £ 20,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>380,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.05</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >

                      <a href="https://www.youtube.com/watch?v=m4UgOO_uhug"> https://www.youtube.com/watch?v=m4UgOO_uhug </a>

                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 03
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Brand Formation, Proposal for PwC Soft Equity Deal, Additional Investments / Early Incubation Round"
                    // description="Industry Partners Join - Please Note Soft Equity Arrangement."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 50,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,375,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 50,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>208,00</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.24</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Professional Investors Join
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 04
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="PwC Soft Equity Deal, Product Development & Team Assembly"
                    // description="Early Funding Round For Cornerstone Investors."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "50,000"}
                        />
                      </Col>
                    </Row>

                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 50,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>201,760</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.25</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      PwC - Soft Equity Deal
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}

                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 05
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="350 PPM Completes Incubation Round"
                    // description="Letter of Intent from Major Distributor - Further Details to be made Available."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 313,975"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 313,975</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>1,292,291</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.24</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      350 PPM’s Research on Grid Scale Energy Storage is here: <a href="https://350ppm.co.uk/sector-research-note-grid-scale-energy-storage/"> https://350ppm.co.uk/sector-research-note-grid-scale-energy-storage/</a>
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 06
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Wins NAM Challenge For Utility Scale Energy Storage"
                    // description="Letter of Intent from Manufacturer, who will cash-flow manufacture."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "10,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 9,500,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> £ 10,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>12,192</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 0.82</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      <a href=" https://www.storelectric.com/storelectric-wins-nam70-challenge-with-its-solution-for-large-scale-storage-of-renewable-energy/"> https://www.storelectric.com/storelectric-wins-nam70-challenge-with-its-solution-for-large-scale-storage-of-renewable-energy/</a>
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 07
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="350 PPM Complete Expansion Round / Series A"
                    // description="Crowdfunding Opens With Support from Industry Contacts and Cornerstone Investors,"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 1,800,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 13,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 1,800,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>1,605,357</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 1.12</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      <a href="https://vimeo.com/301583177">https://vimeo.com/301583177</a>
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </Button> */}
                          <div className="text-center">
                            <Button
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Completed
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 08
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Invention and Wide Ranging Green Hydrogen Patent Granted in UK. EU, US, Canada and China Pending, ROR in process"
                    // description="Product Now Fully Developer and Tested With 1.2M Cash In Bank"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={100.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "30,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 37,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 30,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 10,702</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2.80</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      <a href="https://350ppm.co.uk/sector-research-hydrogen-is-rocket-fuel-the-fuel-of-the-future/">https://350ppm.co.uk/sector-research-hydrogen-is-rocket-fuel-the-fuel-of-the-future/</a>
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              onClick={showModal3}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Pledge
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 09
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Storelectric Begins Project and Finance Campaign through to SPAC"
                    // description="Product Launch. Based on Successful Launch and Inventive Marketing (think Mini Car), additional Distributors Join and cover 40% of distribution market"
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 40,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 660,515 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 3.03</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Anticipates 12 Month Tear Through to Start of Construction on First Project, by which time SPAC will be arranged
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              onClick={showModal3}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Pledge
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 10
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Term Sheet Agreed for 1st Project"
                    // description="The Manufacturer Now Further Realises Potential of Product and as They Are Cashflowing Manufacture Acquire a Shareholding."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 45,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>616,481</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 3.24</Text>
                      </Col>
                    </Row>
                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric Signs Heads of Terms for First Project
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
                            <Button
                              onClick={showModal3}
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              Pledge
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 11
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="First Site Contracted - Pre-Construction Development Begins"
                    // description="Marketing, Promotion and Distribution Further Accelerates across drinks, bars, ice cream."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 50,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 579,492 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 3.45</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric Signs two more Heads of Terms for Project Sites, Inks Partnership Agreement for use of first site. Pre-construction developmnet on said site begins
                    </div>
                  </Card>
                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 12
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Identify and begin negotiations on 7 further sites in UK or Abroad"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 60,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 502,226 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 3.98</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric continues search for additional sites and begins negotiations on them while continuing the sea
                    </div>
                  </Card>




                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 13
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Pre-construction development begins on the 2 further contracted sites"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 70,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 444,829 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 4.50</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric Signs two more Heads of Terms for Project Sites, Inks Partnership Agreement for use of first site. Pre-construction developmnet on said site begins with new engineering team
                    </div>
                  </Card>







                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 14
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Identify and begin negotiations on 7 further sites in UK or Abroad"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 2,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 8  0,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 400,346 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 5.00</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric continues search for additional sites and begins negotiations on them while continuing pre-construction development
                    </div>
                  </Card>






                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 15
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Storelectric Begins Corporate Broker / VC Round"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 20,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 160,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 20,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 2,051,774 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 9.75</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric is looking to raise 100M to begin construction on the first site, adds to pipeline
                    </div>
                  </Card>













                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 16
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Identify and begin negotiations on 7 further sites in UK or Abroad"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 20,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 200,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 20,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 1,846,596 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 10.83</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Storelectric continues search for additional sites and begins negotiations on them while continuing the sea
                    </div>
                  </Card>






                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 17
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="SPAC Launched / Exit Now Possible for Investors"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 20,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 240,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 20,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 1,692,713 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 11.82</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Project Construction Starts / Exits Possible
                    </div>
                  </Card>








                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 18
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Final PRE SPAC Round"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 20,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 280,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 20,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 1,571,805 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 12.72</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Domestic Projects Reach Stage of Ready to Build. International Pipeline Grows
                    </div>
                  </Card>






                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 19
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Final PRE SPAC Round"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 20,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 320,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 20,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> 1,473,567 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 13.57</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Domestic Projects Reach Stage of Ready to Build. International Pipeline Grows
                    </div>
                  </Card>







                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 20
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="Project Construction Starts"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={100.0}
                          format={() => "£ 500,000,000"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 500,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 500,000,000</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>25,050,647 </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 19.96</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Project Construction Starts / SPAC Aquires Storelectric / Storelectric now listed entity / Exits Possible
                    </div>
                  </Card>







                  <Card
                    actions={[
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <button type="primary" htmlType="submit" block className="buttonColor">
                                                        Pledge
                                                    </button> */}
                          <div className="text-center">
                            <Button
                              ton
                              className="default-btn"
                              style={{ color: "white" }}
                            >
                              <LockOutlined />
                            </Button>
                          </div>
                        </Col>
                      </Row>,
                    ]}
                  >
                    <div className="cardbackOpportunities">
                      <br></br>
                      <br></br>
                      <Text style={{ fontSize: "25px", color: "white" }}>
                        Milestone 21
                      </Text>
                    </div>
                    <Meta
                      style={{ padding: "18px", textAlign: "center" }}
                      title="First Project Completed and Becomes Operational"
                    // description="MHK Drinks, Bars, and Ice Cream carved out into subsiduary and invite bids from FMCG Majors. Preparation for Food Line Continue. Sale completes."
                    />
                    <Divider style={{ margin: "0px" }} />
                    <Row span={24} style={{ marginTop: "18px" }}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Percentage Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                            marginTop: "0px",
                          }}
                          percent={0.0}
                        />
                      </Col>
                    </Row>
                    <Row span={24}>
                      <Col span={19} offset={2}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Amount Raised So Far
                        </Text>
                        <Progress
                          strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                          }}
                          style={{
                            marginBottom: "10px",
                          }}
                          percent={0.0}
                          format={() => "---"}
                        />
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ padding: "0px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Pre-Money Valuation
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 2,500,000,000</Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Physical Cash Required
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>---</Text>
                      </Col>
                    </Row>
                    <Row
                      span={24}
                      style={{ paddingTop: "12px", textAlign: "center" }}
                    >
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Shares Allocated
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}> --- </Text>
                      </Col>
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Text style={{ fontSize: "14px", color: "#696969" }}>
                          Share Price
                        </Text>
                        <br></br>
                        <Text style={{ fontSize: "20px" }}>£ 49.90</Text>
                      </Col>
                    </Row>

                    <div
                      style={{
                        height: "90px",
                        width: "100%",
                        padding: "13px",
                        color: "#696969",
                        textAlign: "center",
                      }}
                    >
                      Construction On The First Project Completes and operation starts / Exits Possible
                    </div>
                  </Card>








                </OwlCarousel>
              </Col>
            </Row>
          </div>
        </div>
      </section>


















    </>
  );
}
