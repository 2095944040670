import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Row,
  Col,
  Avatar,
  Typography,
  Divider,
  Button,
  Menu,
  Dropdown,
  message,
} from "antd";
import {
  UserOutlined,
  CalendarOutlined,
  ChromeOutlined,
  MailOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import ReadMoreReact from "read-more-react";
import { apiInstance } from "../../config/axios.config";
import DashboardHeader from "../common/header";
import "../../styles/all.css";
import "../../styles/style.css";

export default function UserProfile() {
  const history = useHistory();
  const { Title } = Typography;
  const { Paragraph } = Typography;

  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const response = await apiInstance.post("user", {
        userId: userId,
      });

      console.log(response.data.data);
      setUser(response.data.data[0]);
    } catch (error) {
      message.error("Something went wrong, please try again!");
    }
  };

  return (
    <div>
      <DashboardHeader />
      {user && (
        <Row style={{ padding: "15px" }}>
          <Col xl={1} lg={1} md={1}></Col>
          <Col xl={20} lg={20} md={20} sm={24} xs={24}>
            <div className="userprofdata">

              <h1 style={{ marginBottom: "20px", fontSize: "24px" }}>
                User Profile Page
              </h1>
            </div>
          </Col>
          <Col xl={1} lg={1} md={1}></Col>
          {/* <Col span={4} offset={16}>
                                <Button type="primary">Change Password</Button>
                            </Col> */}

          <Col xl={24} lg={25} md={24} sm={24}>
            <Card
              style={{
                width: "100%",
                height: "300px",
                padding: "35px",
                border: "none",
              }}
            >
              <Row>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  <div className="text-center">

                    <Avatar
                      size={200}
                      icon={<UserOutlined />}
                      className="userprofileavatar"
                    >
                      USER PROFILE PICTURE
                    </Avatar>
                    <Divider type="vertical" style={{ height: "230px" }} />
                  </div>
                </Col>
                <Col xl={9} lg={9} md={9} sm={12} xs={12} style={{ marginTop: "10px" }}>
                  <div className="userprofdata">


                    <Title level={5}>
                      <UserOutlined /> Firstname
                    </Title>
                    {user.firstName}
                    <Title level={5}>
                      <UserOutlined /> Lastname
                    </Title>
                    {user.lastName}
                    <Title level={5}>
                      <UserOutlined /> Gender
                    </Title>
                    <Paragraph>{user.gender}</Paragraph>
                    <Title level={5}>
                      <UserOutlined /> Country
                    </Title>
                    <Paragraph>{user.country}</Paragraph>
                  </div>
                </Col>
                <Col xl={9} lg={9} md={9} sm={12} xs={12} style={{ marginTop: "10px" }}>
                  <div className="userprofdata">

                    <Title level={5}>
                      <CalendarOutlined /> Date of Birth
                    </Title>
                    {user.dob}
                    <Title level={5}>
                      <UserOutlined /> Phone Number
                    </Title>
                    <Paragraph>{user.phoneNumber}</Paragraph>
                    {/* <Title level={5}><UserOutlined /> Street Address</Title>
                                    <Paragraph>{user.address}</Paragraph> */}
                    <Title level={5}>
                      <ChromeOutlined /> Email
                    </Title>
                    {user.email}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
