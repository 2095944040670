import React, { useState, useEffect } from 'react';
import { MailOutlined, UserOutlined, CalendarOutlined, ChromeOutlined, HighlightOutlined } from '@ant-design/icons';
import { Row, Col, Menu, Dropdown, Avatar, Typography, Divider, Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

export default function DashboardHeader() {
    let history = useHistory();

    const [header, setHeader] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('userId');
        if (user === null) {
            setHeader(false);
        } else {
            setHeader(true);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('email');
        localStorage.removeItem('milestones');
        history.push('/auth')
    }

    const menu = (
        <Menu style={{ marginTop: "20px" }}>
            <Menu.Item>
                <a onClick={() => { history.push('/useraccountpage') }}>
                    My Account
                </a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => { history.push('/myinvestments') }}>
                    My Investments
                </a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => { history.push('/myprojects') }}>
                    My Projects
                </a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => { history.push('/contact') }}>
                    Help
                </a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={logout}>
                    Logout
                </a>
            </Menu.Item>

        </Menu>
    );

    const logoClick = () => {
        history.push('/');
    }

    const investOppspage = () => {
        history.push('/investopps');
    }

    const gotoContact = () => {
        history.push('/contact');
    }

    const signUp = () => {
        history.push('/auth');
    }

    const headerButton = () => {
        $('ul').slideToggle();
        console.log('i am hitting ...');
    }

    return (
        <>
            {
                header ?
                    // <header id="header">
                    //     < div class="container-fluid" >
                    //         <div class="header-wrap clearfix">
                    //             <div class="header-left">
                    //                 <div class="logo">
                    //                     <a onClick={logoClick}></a>
                    //                 </div>
                    //             </div>
                    //             <div class="header-right">
                    //                 <div class="top-contactdetail">
                    //                     <ul>
                    //                         <li><a href="https://www.facebook.com/demec9" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    //                         <li><a href="https://twitter.com/Demec91" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    //                         <li><a href="https://www.instagram.com/demec99/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    //                         <li><a href="https://www.linkedin.com/company/demec9/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    //                         <li><a href="mailto:hello@demec9.com" target="_blank">hello@demec9.com</a></li>
                    //                     </ul>
                    //                 </div>
                    //                 <div class="menu">
                    //                     <div class="navbar-header">
                    //                         <div class="collapse-nav">
                    //                             <button class="collapse-button" type="button">
                    //                                 <span class="icon-bar"></span>
                    //                                 <span class="icon-bar"></span>
                    //                                 <span class="icon-bar"></span>
                    //                             </button>
                    //                         </div>
                    //                     </div>
                    //                     <ul>
                    //                         <li><a onClick={() => history.push('/')}>Home</a></li>
                    //                         <li><a onClick={investOppspage}>Investment Opportunities</a></li>
                    //                         <li><a href="https://demec9.com/blog">Research</a></li>
                    //                         <li><a onClick={gotoContact}>contact</a></li>
                    //                         <li><Dropdown overlay={menu}>
                    //                             <a>
                    //                                 <Avatar
                    //                                     className="avtarclass" onClick={e => e.preventDefault()}
                    //                                     icon={<UserOutlined />}
                    //                                 />
                    //                             </a>
                    //                         </Dropdown>
                    //                         </li>
                    //                     </ul>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div >
                    // </header >



                    <header id="header">
                        <div class="container-fluid">
                            <div class="header-wrap clearfix">
                                <div class="header-left">
                                    <div class="logo">
                                        <a onClick={logoClick}></a>
                                    </div>
                                </div>
                                <div class="header-right">
                                    <div class="top-contactdetail" style={{zIndex: 99999}}>
                                        <ul class="social-menu">
                                            <li><a href="https://www.facebook.com/demec9" target="_blank" class="f12"><i class="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/Demec91" target="_blank" class="f12"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/demec99/" target="_blank" class="f12"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/demec9/" target="_blank" class="f12"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="mailto:hello@demec9.com" target="_blank" class="f12" class="f12">hello@demec9.com</a></li>
                                        </ul>
                                    </div>
                                    <div class="menu">
                                        <div class="navbar-header">
                                            <div class="collapse-nav">
                                                <button class="collapse-button" type="button" onClick={headerButton}>
                                                    <span class="icon-bar"></span>
                                                    <span class="icon-bar"></span>
                                                    <span class="icon-bar"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <div>


                                            <ul>
                                                <li><a onClick={() => history.push('/')}>Home</a></li>
                                                <li><a onClick={investOppspage}>Investment Opportunities</a></li>
                                                <li><a href="https://demec9.com/blog">Research</a></li>
                                                <li><a onClick={gotoContact}>contact</a></li>
                                                <li><Dropdown overlay={menu}>
                                                    <a>
                                                        <Avatar
                                                            className="avtarclass" onClick={e => e.preventDefault()}
                                                            icon={<UserOutlined />}
                                                        />
                                                    </a>
                                                </Dropdown>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>


                    :
                    // <header id="header">
                    //     <div class="container-fluid">
                    //         <div class="header-wrap clearfix">
                    //             <div class="header-left">
                    //                 <div class="logo">
                    //                     <a onClick={logoClick}></a>
                    //                 </div>
                    //             </div>
                    //             <div class="header-right">
                    //                 <div class="top-contactdetail">
                    //                     <ul>
                    //                         <li><a href="https://www.facebook.com/demec9" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    //                         <li><a href="https://twitter.com/Demec91" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    //                         <li><a href="https://www.instagram.com/demec99/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    //                         <li><a href="https://www.linkedin.com/company/demec9/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    //                         <li><a href="mailto:hello@demec9.com" target="_blank">hello@demec9.com</a></li>
                    //                     </ul>
                    //                 </div>
                    //                 <div class="menu">
                    //                     <ul>
                    //                         <li><a onClick={() => history.push('/')}>Home</a></li>
                    //                         <li><a onClick={investOppspage}>Investment Opportunities</a></li>
                    //                         <li><a href="https://demec9.com/blog/">Research</a></li>
                    //                         <li><a onClick={gotoContact}>contact</a></li>
                    //                         <li><a onClick={signUp}>Sign Up</a></li>
                    //                     </ul>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </header>



                    <header id="header">
                        <div class="container-fluid ">
                            <div class="header-wrap clearfix">
                                <div class="header-left">
                                    <div class="logo">
                                        <a onClick={logoClick}></a>
                                    </div>
                                </div>
                                <div class="header-right">
                                    <div class="top-contactdetail" style={{zIndex: 99999}}>
                                        <ul class="social-menu">
                                            <li><a href="https://www.facebook.com/demec9" target="_blank" class="f12"><i class="fab fa-facebook"></i></a></li>
                                            <li><a href="https://twitter.com/Demec91" target="_blank" class="f12"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/demec99/" target="_blank" class="f12"><i class="fab fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/demec9/" target="_blank" class="f12"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="mailto:hello@demec9.com" target="_blank" class="f12">hello@demec9.com</a></li>
                                        </ul>
                                    </div>
                                    <div class="menu">
                                        <div class="navbar-header">
                                            <div class="collapse-nav">
                                                <button class="collapse-button" type="button" onClick={headerButton}>
                                                    <span class="icon-bar"></span>
                                                    <span class="icon-bar"></span>
                                                    <span class="icon-bar"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <ul>
                                            <li><a onClick={() => history.push('/')}>Home</a></li>
                                            <li><a onClick={investOppspage}>Investment Opportunities</a></li>
                                            <li><a href="https://demec9.com/blog/">Research</a></li>
                                            <li><a onClick={gotoContact}>contact</a></li>
                                            <li><a onClick={signUp}>Sign Up</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>



            }
        </>
    );
}