import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Steps,
  Typography,
  message,
  Spin,
  DatePicker,
  Select,
  Radio,
  Space,
  Divider,
} from "antd";
import Selectr from "react-select";
import countryList from "react-select-country-list";
import { useHistory } from "react-router-dom";
import { apiInstance } from "../../config/axios.config";
import "../../App.css";

import "../../styles/all.css";
import "../../styles/style.css";

import { MailOutlined } from "@ant-design/icons";
import DashboardHeader from "../common/header";

export default function Invest() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [radioValue, setRadioValue] = useState("none of the above");
  const [radioValueStep2, setRadioValueStep2] = useState(
    "NO, I AM NOT FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATION SYSTEMS."
  );
  const [radioValueStep4, setRadioValueStep4] = useState(
    "Business Interest for Investment"
  );
  const [investmentDetailSalary, setInvestmentDetailSalary] = useState("");
  const [investmentDetail, setInvestmentDetail] = useState("");
  const [education, setEducation] = useState();
  const { Step } = Steps;
  const { Option } = Select;

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function handleChange(value) {
    console.log(`selected ${value}`);
    setInvestmentDetailSalary(value);
  }

  function handleChangeStep3(value) {
    console.log(`selected ${value}`);
    setInvestmentDetail(value);
  }

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };

  const onChangeStepTwo = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValueStep2(e.target.value);
  };

  const onChangeStep4 = (e) => {
    console.log("radio checked", e.target.value);
    setRadioValueStep4(e.target.value);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const response = await apiInstance.post("invest", {
        investorClassification: radioValue,
        fcaInvestorStatus: radioValueStep2,
        investmentDetailsRange: `${investmentDetailSalary} to ${investmentDetail}`,
        businessInterest: radioValueStep4,
        education: education,
        userId: userId,
      });
      console.log(response.data);

      const { status } = response.data;
      if (status) {
        message.success("project created successfully!");
        history.push("/investsuccess");
      }
    } catch (error) {
      message.error("something went wrong!");
    } finally {
      setLoading(false);
    }
  };


  const onFinishStep2Form = () => {
    setCurrent(current + 1);
  }

  const steps = [
    {
      title: "Investor Classification",
      content: (
        <Card className="cardStyle" style={{ marginTop: "30px" }}>
          <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Select investor type:
          </h3>
          <Radio.Group onChange={onChange} value={radioValue}>
            <Space direction="vertical">
              <Radio value="High Net Worth">High Net Worth</Radio>
              <Radio value="Sophisticated">Sophisticated</Radio>
              <Radio value="Elected Professional">Elected Professional</Radio>
              <Radio value="Professional Investor">Professional Investor</Radio>
              <Radio value="Not Sure">Not Sure</Radio>
              <Radio value="none of the above">None of the above</Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="text-center">
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={() => next()}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      title: "FCA Investor Status",
      content: (
        <Card className="cardStyle" style={{ marginTop: "30px" }}>
          <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Please confirm below, that you are familiar with the FCA investor
            status classifications and thus have a reasonable confidence that
            the status you have selected is correct.
          </h3>
          <Radio.Group onChange={onChangeStepTwo} value={radioValueStep2}>
            <Space direction="vertical">
              <Radio value="NO, I AM NOT FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATION SYSTEMS.">
                No, I am not familiar with the financial conduct authorityös
                investor classification systems.
              </Radio>
              <Radio value="YES, I CONFIRM I AM FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATIONS.">
                Yes, I confirm I am familiar with the financial conduct
                authority's investor classifications.
              </Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="text-center">
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={() => prev()}
                >
                  Back
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={() => next()}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      ),
    },
    {
      title: "Investment Details",
      content: (
        <Card className="cardStyle" style={{ marginTop: "30px" }}>



          <Form layout="vertical" onFinish={onFinishStep2Form} >
            <Form.Item name="one" label="Choose the following Salary Bracket per annum and Range of
            Investment per annum:" rules={[{ required: true, message: "this field is required", }]}>
              <Select
                defaultValue="Salary Bracket per annum"
                onChange={handleChange}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
              >
                <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
                <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
                <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
                <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

                <Option value="£ 100000 - £ 1000000">
                  £ 100,000 - £ 1,000,000
                </Option>
                <Option value="£ 1000000 - £ 3000000">
                  £ 1,000,000 - £ 3,000,000
                </Option>
                <Option value="£ 3000000 - £ 5000000">
                  £ 3,000,000 - £ 5,000,000
                </Option>

                <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
              </Select>
            </Form.Item>
            <Form.Item name="two" label="" rules={[{ required: true, message: "this field is required", }]}>
              <Select
                defaultValue="Range of Investment per annum"
                onChange={handleChangeStep3}
                size="large"
                className="text-grey"
                style={{ width: '100%' }}
              >
                <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
                <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
                <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
                <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

                <Option value="£ 100000 - £ 1000000">
                  £ 100,000 - £ 1,000,000
                </Option>
                <Option value="£ 1000000 - £ 3000000">
                  £ 1,000,000 - £ 3,000,000
                </Option>
                <Option value="£ 3000000 - £ 5000000">
                  £ 3,000,000 - £ 5,000,000
                </Option>

                <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
              </Select>
            </Form.Item>


            <Divider />
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="text-center">
                  <Button
                    type="primary"
                    className="default-btn"
                    onClick={() => prev()}
                  >
                    Back
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="default-btn"
                  // onClick={() => next()}
                  >
                    Next
                  </Button>
                </div>
              </Col>
            </Row>


          </Form>


          {/* <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Choose the following Salary Bracket per annum and Range of
            Investment per annum:
          </h3>
          <div style={{ marginTop: "30px" }}>
            <Select
              defaultValue="Salary Bracket per annum"
              onChange={handleChange}
              size="large"
              className="text-grey"
              style={{ width: '100%' }}
            >
              <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
              <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
              <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
              <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

              <Option value="£ 100000 - £ 1000000">
                £ 100,000 - £ 1,000,000
              </Option>
              <Option value="£ 1000000 - £ 3000000">
                £ 1,000,000 - £ 3,000,000
              </Option>
              <Option value="£ 3000000 - £ 5000000">
                £ 3,000,000 - £ 5,000,000
              </Option>

              <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
            </Select>
          </div> */}


          {/* <div style={{ marginTop: "30px" }}>
            <Select
              defaultValue="Range of Investment per annum"
              onChange={handleChangeStep3}
              size="large"
              className="text-grey"
              style={{ width: '100%' }}
            >
              <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
              <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
              <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
              <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

              <Option value="£ 100000 - £ 1000000">
                £ 100,000 - £ 1,000,000
              </Option>
              <Option value="£ 1000000 - £ 3000000">
                £ 1,000,000 - £ 3,000,000
              </Option>
              <Option value="£ 3000000 - £ 5000000">
                £ 3,000,000 - £ 5,000,000
              </Option>

              <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
            </Select>
          </div> */}


          {/* <Divider />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="text-center">
                <Button
                  type="primary"
                  className="default-btn"
                  onClick={() => prev()}
                >
                  Back
                </Button>
                &nbsp; &nbsp;
                <Button
                  type="primary"
                  className="default-btn"
                  onClick={() => next()}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row> */}
        </Card>
      ),
    },
    {
      title: "Business Interest",
      content: (
        <Card className="cardStyle" style={{ marginTop: "30px" }}>
          <h3 style={{ marginBottom: "10px" }} className="text-grey">
            Type of business you are interested to invest:
          </h3>
          <Radio.Group onChange={onChangeStep4} value={radioValueStep4}>
            <Space direction="vertical">
              <Radio value="Business Interest for Investment">
                Business Interest for Investment
              </Radio>
              <Radio value="Energy Distribution">Energy Distribution</Radio>
              <Radio value="Manufacturing Industries">
                Manufacturing Industries
              </Radio>
              <Radio value="Chemical Industries">Chemical Industries</Radio>
              <Radio value="Transport">Transport</Radio>
              <Radio value="Mining/Mineral Production">
                Mining/Mineral Production
              </Radio>
              <Radio value="Fugitive Emission">Fugitive Emission</Radio>
              <Radio value="Solvent Use"> Solvent Use </Radio>
              <Radio value="Waste Handling and disposal ">
                {" "}
                Waste Handling and disposal{" "}
              </Radio>
              <Radio value="Afforestation and reforestation">
                {" "}
                Afforestation and reforestation{" "}
              </Radio>
              <Radio value="Agriculture"> Agriculture </Radio>
              <Radio value="Other"> Other </Radio>
            </Space>
          </Radio.Group>
          <Divider />
          <h3>Type your education:</h3>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="education"
              rules={[
                { required: true, message: "Please input your education!" },
              ]}
            >
              <Input
                size="large"
                placeholder="EDUCATION"
                onChange={(e) => setEducation(e.target.value)}
              />
            </Form.Item>
          </Form>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="text-center">
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={() => prev()}
                >
                  Back
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="default-btn"
                  htmlType="submit"
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      <DashboardHeader />
      <Row>
        <Col
          xl={4}
          lg={4}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
        <Col
          xl={16}
          lg={16}
          md={16}
          sm={20}
          xs={20}
          style={{ marginTop: "100px" }}
        >
          <h1>Investor Registration</h1>

          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
      </Row>
      <Row>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
        <Col
          xl={8}
          lg={8}
          md={16}
          sm={20}
          xs={20}
          style={{ marginTop: "100px" }}
        >
          <Card className="mb-3" style={{ border: 0 }}>
            <div className="steps-content">{steps[current].content}</div>
          </Card>
        </Col>
        <Col
          xl={8}
          lg={8}
          md={4}
          sm={2}
          xs={2}
          style={{ marginTop: "100px" }}
        ></Col>
      </Row>
    </Spin>
  );
  // }
  //   const history = useHistory();
  //   const [loading, setLoading] = useState(false);
  //   const [current, setCurrent] = useState(0);
  //   const [radioValue, setRadioValue] = useState("none of the above");
  //   const [radioValueStep2, setRadioValueStep2] = useState(
  //     "NO, I AM NOT FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATION SYSTEMS."
  //   );
  //   const [radioValueStep4, setRadioValueStep4] = useState(
  //     "Business Interest for Investment"
  //   );
  //   const [investmentDetailSalary, setInvestmentDetailSalary] = useState("");
  //   const [investmentDetail, setInvestmentDetail] = useState("");
  //   const [education, setEducation] = useState();
  //   const { Step } = Steps;
  //   const { Option } = Select;
  //   const { TextArea } = Input;
  //   const [value, setValue] = useState("");
  //   const options = useMemo(() => countryList().getData(), []);

  //   const changeHandler = (value) => {
  //     setValue(value);
  //   };

  //   const next = () => {
  //     setCurrent(current + 1);
  //   };

  //   const prev = () => {
  //     setCurrent(current - 1);
  //   };

  //   function handleChange(value) {
  //     console.log(`selected ${value}`);
  //     setInvestmentDetailSalary(value);
  //   }

  //   function handleChangeStep3(value) {
  //     console.log(`selected ${value}`);
  //     setInvestmentDetail(value);
  //   }

  //   const onChange = (e) => {
  //     console.log("radio checked", e.target.value);
  //     setRadioValue(e.target.value);
  //   };

  //   const onChangeStepTwo = (e) => {
  //     console.log("radio checked", e.target.value);
  //     setRadioValueStep2(e.target.value);
  //   };

  //   const onChangeStep4 = (e) => {
  //     console.log("radio checked", e.target.value);
  //     setRadioValueStep4(e.target.value);
  //   };

  //   const investPage = () => {
  //     history.push("/invest");
  //   };

  //   const raisePage = () => {
  //     history.push("/raise");
  //   };

  //   const signUp = () => {
  //     history.push("/auth");
  //   };

  //   const investOppspage = () => {
  //     history.push("/investopps");
  //   };

  //   const gotoContact = () => {
  //     history.push("/contact");
  //   };

  //   const onFinish = (values) => {
  //     console.log("Success:", values);
  //   };

  //   const onFinishFailed = (errorInfo) => {
  //     console.log("Failed:", errorInfo);
  //   };

  //   const done = async () => {
  //     try {
  //       const userId = localStorage.getItem("userId");
  //       const response = await apiInstance.post("invest", {
  //         investorType: radioValue,
  //         fcaInvestor: radioValueStep2,
  //         salaryBracket: {
  //           salaryBracketPerAnnum: investmentDetail,
  //           rangeOfInvestmentPerAnnum: investmentDetailSalary,
  //         },
  //         bussinessInterest: radioValueStep4,
  //         education: education,
  //         userId: userId,
  //       });
  //       console.log(response.data);
  //       const { status } = response.data;
  //       if (status) {
  //         message.success("Investor created successfully!");
  //         history.push("/investsuccess");
  //       }
  //     } catch (error) {
  //       message.error("something went wrong!");
  //     }
  //   };

  //   const { Title } = Typography;
  //   const steps = [
  //     {
  //       title: "Investor Classification",
  //       content: (
  //         <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
  //           <h3 style={{ marginBottom: "10px" }} className="text-grey">
  //             Select Investor type:
  //           </h3>
  //           <Radio.Group onChange={onChange} value={radioValue}>
  //             <Space direction="vertical">
  //               <Radio value="High Net Worth">High Net Worth</Radio>
  //               <Radio value="Sophisticated">Sophisticated</Radio>
  //               <Radio value="Elected Professional">Elected Professional</Radio>
  //               <Radio value="Professional Investor">Professional Investor</Radio>
  //               <Radio value="Not Sure">Not Sure</Radio>
  //               <Radio value="none of the above">None of the above</Radio>
  //             </Space>
  //           </Radio.Group>
  //           <Divider />
  //           <Row span={24}>
  //             {/* <Col span={5} offset={6}>
  //                         <a class="default-btn" htmlType="submit" onClick={() => prev()} style={{ color: "white", fontSize: "20px", marginTop: "20px" }}>Back</a>
  //                     </Col> */}
  //          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
  //            <div className='text-center'>
  //            <Button
  //                 className="default-btn"
  //                 htmlType="submit"
  //                 onClick={() => next()}
  //               >
  //                 Next
  //               </Button>
  //            </div>

  //             </Col>
  //           </Row>
  //         </Card>
  //       ),
  //     },
  //     {
  //       title: "FCA Investor Status",
  //       content: (
  //         <Card className="cardStyle mb-3 " style={{ marginTop: "30px" }}>
  //           <h3 style={{ marginBottom: "10px" }} className="text-grey">
  //             Please confirm below, that you are familiar with the FCA investor
  //             status classifications and thus have a reasonable confidence that
  //             the status you have selected is correct.
  //           </h3>
  //           <Radio.Group onChange={onChangeStepTwo} value={radioValueStep2}>
  //             <Space direction="vertical">
  //               <Radio value="NO, I AM NOT FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATION SYSTEMS.">
  //                 No, I am not familiar with the financial conduct authorityös
  //                 investor classification systems.
  //               </Radio>
  //               <Radio value="YES, I CONFIRM I AM FAMILIAR WITH THE FINANCIAL CONDUCT AUTHORITY’S INVESTOR CLASSIFICATIONS.">
  //                 Yes, I confirm I am familiar with the financial conduct
  //                 authority's investor classifications.
  //               </Radio>
  //             </Space>
  //           </Radio.Group>
  //           <Divider />
  //           <Row >
  //           <Col xl={24} lg={24} md={24} sm={24} xs={24}>
  //               <div className="text-center">
  //               <Button
  //                 className="default-btn"
  //                 htmlType="submit"
  //                 onClick={() => prev()}
  //               >
  //                 Back
  //               </Button>
  //               &nbsp;  &nbsp;
  //               <Button
  //                 className="default-btn"
  //                 htmlType="submit"
  //                 onClick={() => next()}
  //               >
  //                 Next
  //               </Button>
  //               </div>

  //             </Col>
  //           </Row>
  //         </Card>
  //       ),
  //     },
  //     {
  //       title: "Investment Details",
  //       content: (
  //         <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
  //           <h3 style={{ marginBottom: "10px" }} className="text-grey">
  //             Choose the following Salary Bracket per annum and Range of
  //             Investment per annum:
  //           </h3>

  //               <Select
  //                 defaultValue="Salary Bracket per annum"
  //                 onChange={handleChange}
  //                 style={{ width: "100%" }}
  //                 size="large"
  //               >
  //                 <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
  //                 <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
  //                 <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
  //                 <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

  //                 <Option value="£ 100000 - £ 1000000">
  //                   £ 100,000 - £ 1,000,000
  //                 </Option>
  //                 <Option value="£ 1000000 - £ 3000000">
  //                   £ 1,000,000 - £ 3,000,000
  //                 </Option>
  //                 <Option value="£ 3000000 - £ 5000000">
  //                   £ 3,000,000 - £ 5,000,000
  //                 </Option>

  //                 <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
  //               </Select>

  //          <div className="mb-2"></div>
  //               <Select
  //                 defaultValue="Range of Investment per annum"
  //                 onChange={handleChangeStep3}
  //                 style={{ width: "100%" }}
  //                 size="large"
  //               >
  //                 <Option value="£ 0 - £ 24999">£ 0 - £ 24,999</Option>
  //                 <Option value="£ 25000 - £ 49999">£ 25,000 - £ 49,999</Option>
  //                 <Option value="£ 50000 - £ 74999">£ 50,000 - £ 74,999</Option>
  //                 <Option value="£ 75000 - £ 100000">£ 75,000 - £ 100,000</Option>

  //                 <Option value="£ 100000 - £ 1000000">
  //                   £ 100,000 - £ 1,000,000
  //                 </Option>
  //                 <Option value="£ 1000000 - £ 3000000">
  //                   £ 1,000,000 - £ 3,000,000
  //                 </Option>
  //                 <Option value="£ 3000000 - £ 5000000">
  //                   £ 3,000,000 - £ 5,000,000
  //                 </Option>

  //                 <Option value="Above £ 5,000,000">Above £ 5,000,000</Option>
  //               </Select>

  //           <Divider />
  //           <Row>
  //           <Col xl={24} lg={24} md={24} sm={24} xs={24}>
  //               {/* <a class="default-btn" htmlType="submit" onClick={() => prev()} style={{ color: "white", fontSize: "20px", marginTop: "10px" }}>Back</a> */}
  //               <div className="text-center">

  //               <Button
  //                 type="primary"

  //                 className="default-btn"
  //                 onClick={() => prev()}
  //               >
  //                 Back
  //               </Button>
  //               &nbsp;  &nbsp;
  //               {/* <a class="default-btn" htmlType="submit" onClick={() => next()} style={{ color: "white", fontSize: "20px", marginTop: "10px" }}>Next</a> */}
  //               <Button
  //                 type="primary"

  //                 className="default-btn"
  //                 onClick={() => next()}
  //               >
  //                 Next
  //               </Button>
  //               </div>
  //             </Col>pf
  //           </Row>
  //         </Card>
  //       ),
  //     },
  //     {
  //       title: "Business Interest",
  //       content: (
  //         <Card className="cardStyle mb-3" style={{ marginTop: "30px" }}>
  //           <h3 style={{ marginBottom: "10px" }} className="text-grey">
  //             Type of business you are interested to invest:
  //           </h3>
  //           <Radio.Group onChange={onChangeStep4} value={radioValueStep4}>
  //             <Space direction="vertical">
  //               <Radio value="Business Interest for Investment">
  //                 Business Interest for Investment
  //               </Radio>
  //               <Radio value="Energy Distribution">Energy Distribution</Radio>
  //               <Radio value="Manufacturing Industries">
  //                 Manufacturing Industries
  //               </Radio>
  //               <Radio value="Chemical Industries">Chemical Industries</Radio>
  //               <Radio value="Transport">Transport</Radio>
  //               <Radio value="Mining/Mineral Production">
  //                 Mining/Mineral Production
  //               </Radio>
  //               <Radio value="Fugitive Emission">Fugitive Emission</Radio>
  //               <Radio value="Solvent Use"> Solvent Use </Radio>
  //               <Radio value="Waste Handling and disposal ">
  //                 {" "}
  //                 Waste Handling and disposal{" "}
  //               </Radio>
  //               <Radio value="Afforestation and reforestation">
  //                 {" "}
  //                 Afforestation and reforestation{" "}
  //               </Radio>
  //               <Radio value="Agriculture"> Agriculture </Radio>
  //               <Radio value="Other"> Other </Radio>
  //             </Space>
  //           </Radio.Group>
  //           <Divider />
  //           <h3>Type your education:</h3>
  //           <Form
  //             name="basic"
  //             onFinish={onFinish}
  //             onFinishFailed={onFinishFailed}
  //           >
  //             <Form.Item
  //               name="education"
  //               rules={[
  //                 { required: true, message: "Please input your education!" },
  //               ]}
  //             >
  //               <Input
  //                 size="large"
  //                 placeholder="EDUCATION"
  //                 onChange={(e) => setEducation(e.target.value)}
  //               />
  //             </Form.Item>
  //           </Form>
  //           {/* <Divider /> */}
  //           <Row>
  //           <Col xl={24} lg={24} md={24} sm={24} xs={24}>
  //               <div className="text-center">

  //               <Button
  //                 className="default-btn"
  //                 htmlType="submit"
  //                 onClick={() => prev()}
  //               >
  //                 Back
  //               </Button>
  //           &nbsp; &nbsp;
  //               <Button
  //                 className="default-btn"
  //                 htmlType="submit"
  //                 onClick={() => history.push("/investsuccess")}
  //               >
  //                 Next
  //               </Button>
  //               </div>
  //               {/* <Button className="default-btn" htmlType="submit" onClick={done}>Next</Button> */}
  //             </Col>
  //           </Row>
  //         </Card>
  //       ),
  //     },
  //   ];

  //   return (
  //     <Spin size="large" spinning={loading}>
  //       <DashboardHeader />
  //       <Row>
  //         <Col
  //           xl={4}
  //           lg={4}
  //           md={4}
  //           sm={2}
  //           xs={2}
  //           style={{ marginTop: "100px" }}
  //         ></Col>
  //         <Col
  //           xl={16}
  //           lg={16}
  //           md={16}
  //           sm={20}
  //           xs={20}
  //           style={{ marginTop: "100px" }}
  //         >
  //           <h1>Investor Registration</h1>

  //           <Steps current={current}>
  //             {steps.map((item) => (
  //               <Step key={item.title} title={item.title} />
  //             ))}
  //           </Steps>
  //         </Col>
  //         <Col
  //           xl={4}
  //           lg={4}
  //           md={4}
  //           sm={2}
  //           xs={2}
  //           style={{ marginTop: "100px" }}
  //         ></Col>
  //       </Row>

  //       <Row>
  //         <Col
  //           xl={8}
  //           lg={8}
  //           md={4}
  //           sm={2}
  //           xs={2}
  //           style={{ marginTop: "100px" }}
  //         ></Col>
  //         <Col
  //           xl={8}
  //           lg={8}
  //           md={16}
  //           sm={20}
  //           xs={20}
  //           style={{ marginTop: "100px" }}
  //         >
  //           <Card className="mb-3">
  //             <div className="steps-content">{steps[current].content}</div>
  //           </Card>
  //           {/* <div className="steps-action">
  //                         {current < steps.length - 1 && (
  //                             <Button type="primary" onClick={() => next()}>
  //                                 Next
  //                             </Button>
  //                         )}
  //                         {current === steps.length - 1 && (
  //                             <Button type="primary" onClick={() => message.success('Processing complete!')}>
  //                                 Done
  //                             </Button>
  //                         )}
  //                         {current > 0 && (
  //                             <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
  //                                 Previous
  //                             </Button>
  //                         )}
  //                     </div> */}
  //         </Col>
  //         <Col
  //           xl={8}
  //           lg={8}
  //           md={4}
  //           sm={2}
  //           xs={2}
  //           style={{ marginTop: "100px" }}
  //         ></Col>
  //       </Row>
  //     </Spin>
  //   );
}
