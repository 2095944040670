import React, { useState, useEffect, } from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button, message, Row, Col, Table, Card, Typography } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { apiInstance } from '../../config/axios.config';
import DashboardHeader from '../common/header';

export default function Success() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const { Title } = Typography;
    const { Paragraph } = Typography;

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const userId = localStorage.getItem('userId');
            setLoading(true);
            const response = await apiInstance.get(`invests/${userId}`);

            console.log(response.data.data);
            if (response.data.status) {
                setData(response.data.data);
            }
        } catch (error) {
            message.error('something went wrong!');
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <DashboardHeader />
            {
                // data ?

                //     <Row span={24} style={{ padding: "15px" }}>
                //         <Col span={24}>
                //             <Row span={24} >
                //                 <Col span={4}>
                //                     <h1 style={{ marginLeft: "32px" }}>Raise Profile</h1>
                //                 </Col>
                //                 {/* <Col span={4} offset={16}>
                //                 <Button type="primary">Change Password</Button>
                //             </Col> */}
                //             </Row>
                //             <Card style={{
                //                 width: "100%", height: "300px", padding: "35px", marginBottom: "35px"
                //             }}>
                //                 < Row span={24} gutter={24}>
                //                     <Col span={6} >
                //                         <div style={{ display: 'inline-block' }}>
                //                             <Title level={5}>FCA Investor Status</Title>
                //                             {data[0].fcaInvestorStatus}
                //                         </div>
                //                     </Col>
                //                     <Col span={3} >
                //                         <Title level={5}>Investor Classification</Title>
                //                         {data[0].investorClassification}
                //                     </Col>

                //                     <Col span={4} >
                //                         <Title level={5}> Investment Details Range</Title>
                //                         <Paragraph>{data[0].investmentDetailsRange}</Paragraph>
                //                     </Col>

                //                     <Col span={3} >
                //                         <Title level={5}>Business Interest</Title>
                //                         <Paragraph>{data[0].businessInterest}</Paragraph>
                //                     </Col>

                //                     <Col span={3}>
                //                         <Title level={5}> Education</Title>
                //                         {data[0].education}
                //                     </Col>
                //                     {/* 
                //                     <Col span={3}>
                //                         <Title level={5}>Project Stage</Title>
                //                         <Paragraph>{data[0].projectStage}</Paragraph>

                //                     </Col>
                //                     <Col span={3}>
                //                         <Title level={5}>Additional Information</Title>
                //                         {data[0].additionalInformation}
                //                     </Col> */}
                //                 </Row>
                //                 <Row style={{ marginTop: 20, }}>
                //                     {/* <h1 style={{}}>Project Milestone Table</h1>
                //                     <Col span={24}>
                //                         <Table dataSource={tableData} columns={columns} bordered pagination={false} />
                //                     </Col> */}
                //                 </Row>
                //             </Card>
                //         </Col>
                //     </Row>
                //     :

                    <Result
                        icon={<SmileOutlined />}
                        title="Great, You have successfully registered as an Investor "
                        extra={<Button className="default-btn" htmlType="submit" onClick={() => { history.push('/investopps') }} style={{ width: 300 }}>Investment Opportunities</Button>}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}
                    />
            }
            {/* <Result
                icon={<SmileOutlined />}
                title="Great, You have successfully registered as an Investor "
                extra={<Button className="default-btn" htmlType="submit" onClick={() => { history.push('/investopps') }} style={{ width: 290 }}>Investment Opportunities</Button>}
                style={{ display: "flex", justifyContent: "center" , alignItems: "center", height: "100vh", flexDirection: 'column' }}
            /> */}
        </>
    );

}