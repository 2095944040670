import React, { useState } from "react";
import "../../styles/all.css";
import "../../styles/style.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { useHistory } from "react-router-dom";

import demeclogo from "../../images/logo.png";

import desert2 from "../../images/a-mex-desert-2-1point-5-768x512.jpg";
import mexicali from "../../images/Mexicali-Substation-1-1point-5-768x510.jpg";
import desrt from "../../images/desrt-3-1-point-5-768x511.jpg";
import amex from "../../images/a-mex-desert-2-1point-5-768x512.jpg";
import insand from "../../images/350-in-Sand-1-point5-768x511.jpg";
import aeries from "../../images/aeriesl-1-point-5-768x511.jpg";
import lanov from "../../images/Lanov-114-293-1-oin5-768x512.jpg";
import windandsolarcombo from "../../images/wind-and-solar-combo-e1459763643994-1-point-5-768x512.jpg";
import digger from "../../images/digger-1-point-5-768x514.jpg";
import pointfivr from "../../images/1378700110244-1-point-5-768x509.jpg";
import onepoint from "../../images/P1010515-1-point-5-768x515.jpg";
import DSC from "../../images/DSC00583-1-1-point-5-768x511.jpg";
import anarb from "../../images/anarb-one-point-5-768x512.jpg";
import imgpoint from "../../images/IMG_6523-1point-5-768x510.jpg";
import datang from "../../images/datang.jpg";
import datangtwo from "../../images/datang-hu-1point5-768x513.jpg";
import threeindia from "../../images/03_INDIA-1poinrt5-768x517.jpg";
import compost from "../../images/compost-1point-5-to-1.jpg";
import taizhou from "../../images/Taizhou-Municipal-Solid-Waste-1-point-5.jpg";
import IMGipoint from "../../images/IMG_7421-1poin5-768x507.jpg";
import hydroeight from "../../images/hydro-8-1point5-768x508.jpg";
import smallhydrofive from "../../images/small-hydro-5-1-point-5.jpg";
import huayyangtwo from "../../images/huaiyang-2-1point5-768x514.jpg";
import hydrosevensixeight from "../../images/hydro-5-1point5-768x508.jpg";
import wasteelec from "../../images/Waste-elec-2-1point51-768x515.jpg";
import ccssilo from "../../images/CCS-silo-1point5-768x512.jpg";
import colapoint from "../../images/cola-4-1-point-5.jpg";
import smallhydrodashsix from "../../images/small-hydro-6-1-point-5-768x512.jpg";
import sichuan from "../../images/Sichuan-Da-County-2-poinrt5.jpg";
import henantwo from "../../images/henan-2-1point5-768x513.jpg";
import IMGtwobeing from "../../images/IMG_2444-hydro-being-built-1-1point-5-768x511.jpg";
import hydrosevenpoint from "../../images/hydro-7-1point5-768x512.jpg";
import altebliech from "../../images/640_VH_Alte_Bleiche_StreamDiver.jpg";
import jinyuan from "../../images/jinyaun-1-1-point5-768x513.jpg";
import hydrofouripoint from "../../images/hydro-4-1point5-768x510.jpg";
import hydrothreeonepoint from "../../images/hydro-3-1point5-768x513.jpg";
import anotherDSC from "../../images/DSC03419-1-point-5-768x513.jpg";
import zinjangaltei from "../../images/Xinjiang-Aletai-Huaning-Hydropower-Project-photo_4-1point-5.jpg";
import { MailOutlined } from "@ant-design/icons";
import DashboardHeader from "../common/header";
import { apiInstance } from "../../config/axios.config";

import prj3img from "../../images/GBN_prj3.jpg";
import prj2img from "../../images/SC_prj2.jpg";
import prjimg from "../../images/sadprojimg.jpeg";
import storeElectric from "../../images/store-electric.jpeg";
import { YoutubePlayer } from "reactjs-media";
import {
  Tabs,
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Spin,
  message,
  Checkbox,
  Rate,
  Modal,
  Anchor,
} from "antd";

export default function Homepage() {
  let history = useHistory();

  const { TabPane } = Tabs;
  const { Link } = Anchor;


  const [loading, setLoading] = React.useState(false);
  // const formRef = React.createRef();
  const [responsive, setResponsive] = React.useState({

    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },

  })

  function callback(key) {
    console.log(key);
  }

  const investPage = () => {
    const userId = localStorage.getItem("userId");
    if (userId === null) {
      history.push("/auth");
    } else {
      history.push("/invest");
    }
  };

  const raisePage = () => {
    const userId = localStorage.getItem("userId");
    if (userId === null) {
      history.push("/auth");
    } else {
      history.push("/raise");
    }
  };

  const signUp = () => {
    history.push("/auth");
  };

  const investOppspage = () => {
    history.push("/investopps");
  };

  const gotoContact = () => {
    history.push("/contact");
  };

  const logoClick = () => {
    localStorage.removeItem("userId");
    history.push("/");
  };

  const onFinish = async (values) => {
    try {
      console.log("Success:", values);

      const { email, name, contactMessage } = values;
      setLoading(true);
      const response = await apiInstance.post("/email/contactus", {
        email: email,
        name: name,
        message: contactMessage,
      });
      const { status } = response.data;
      if (status) {
        // formRef.current.resetFields();
        setLoading(false);
        message.success({
          content: "Contact email sent successfully!",
          style: { marginTop: "20vh" },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error({
        content: "Something went wrong please try again!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const reviewHandler = async (values) => {
    try {
      setLoading(true);
      console.log(values);
      const response = await apiInstance.post("/review", {
        title: values.title,
        comment: values.comment,
        reviewScore: reviewValue,
        user: localStorage.getItem('userId'),
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalVisible(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [reviewValue, setReviewValue] = useState(5);
  const onChangeRateHandler = (value) => {
    console.log(value);
    setReviewValue(value);
  };

  return (
    <>
      <DashboardHeader />

      <section id="welcome-section">
        <div class="container-fluid">
          <div class="welcome-content clearfix">
            <Row>
              {/* welcomecontent left */}
              <Col sm={24} md={24} lg={14} xl={14}>
                <div class="welcomecontent-left">
                  <Row>
                    <h1 className="welcomedemec"
                    // style={{ marginBottom: "6px", fontSize: "48px" }}
                    >
                      Welcome to DEMEC
                    </h1>
                    <sup
                      className="sup9">
                      9
                    </sup>
                    <br></br>
                    <h1 className="homedemec"
                    // style={{ marginBottom: "20px", fontSize: "24px" }}
                    >
                      {" "}
                      The Home of Defined Exit Milestone Environmental
                      Crowdfunding
                    </h1>
                  </Row>

                  <p className="text-grey home-para-pad">
                    In 2016 the Paris agreement was signed, a legally binding
                    commitment to deal with the mitigation of greenhouse gas
                    emissions and finance. This has now catalysed a $90 trillion
                    environmental revolution and the commitment to develop over
                    100,000 environmental projects. DEMEC
                    <sup className="sup-p">9</sup> has been launched to help
                    meet the Paris agreement by revolutionising the facilitating
                    of environmental projects and construction around the world.
                    Simply put, DEMEC<sup className="sup-p">9</sup> will
                    introduce project developer and investor together on a
                    defined exit milestone platform, which not only reduces the
                    risk for investors, but develops the very best environmental
                    projects in the timescales required.
                  </p>
                  <p className="text-grey home-para-pad">
                    The team behind DEMEC<sup className="sup-p">9</sup> have
                    vast experience in the environmental sector, developing over
                    50 projects with an aggregated capex of over $4 billion.
                  </p>
                  <Button onClick={gotoContact} className="default-btn mb-3">
                    CONTACT US
                  </Button>
                </div>
              </Col>

              {/* welcomecontent right */}
              <Col sm={24} md={24} lg={10} xl={10}>
                <div class="welcomecontent-right ">
                  <Row>
                    {/* <Col sm={0} md={0} lg={0} xl={4}></Col> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <div class="content-boxs">
                        <p className="text-white">
                          Unlock access to invest in a range of Investment
                          Opportunities. Please register as an Investor.
                        </p>

                        <a onClick={investPage} class="grey-btn">
                          INVEST
                        </a>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} align="left">
                      <div class="content-boxs blue-shade">
                        <p>
                          Register your environmental project for finance &
                          assistance and view in greater depth projects we have
                          already been involved in.
                        </p>
                        <a onClick={raisePage} class="grey-btn">
                          RAISE
                        </a>
                      </div>
                    </Col>
                    {/* <Col sm={0} md={0} lg={0} xl={4}></Col> */}
                  </Row>
                  <Row>
                    <Col sm={24} md={12} lg={12} xl={12}>
                      <div class="content-boxs">
                        <p>
                          View the Environmental Research carried out by our
                          Investor Relations Team at DEMEC<sup>9</sup>.
                        </p>
                        <a href="https://demec9.com/blog/" class="grey-btn">
                          VIEW RESEARCH
                        </a>
                      </div>
                    </Col>

                    <Col sm={24} md={12} lg={12} xl={12}>
                      <Card className="custom-contact-form" style={{ border: 0 }}>
                        <Spin size="small" spinning={loading}>
                          <Tabs
                            defaultActiveKey="1"
                            onChange={callback}
                            centered
                            type="card"
                          >
                            <TabPane tab="Contact Us" key="1">
                              <Form
                                name="basic"
                                formLayout="vertical"
                                onFinish={onFinish}
                              // ref={formRef}
                              >
                                <Form.Item
                                  name="name"
                                  style={{ marginBottom: "18px" }}
                                >
                                  <Input
                                    placeholder="Enter your name"
                                    size="small"
                                  />
                                </Form.Item>
                                <Form.Item
                                  name="email"
                                  style={{ marginBottom: "18px" }}
                                >
                                  <Input placeholder="Email" size="small" />
                                </Form.Item>
                                <Form.Item
                                  name="contactMessage"
                                  style={{ marginBottom: "18px" }}
                                >
                                  <Input.TextArea
                                    placeholder="Message..."
                                    size="small"
                                    rows={1}
                                  />
                                </Form.Item>

                                <Form.Item>
                                  <Row span={24}>
                                    <Col span={8} offset={7}>
                                      <Button
                                        className="grey-default-btn"
                                        htmlType="submit"
                                      >
                                        Send
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </Form>
                            </TabPane>
                          </Tabs>
                        </Spin>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>

      <section id="welcome-section">
        <div class="container-fluid">
          <div class="welcome-content clearfix">
            <Row>
              <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <div style={{ justifyContent: "center" }}>

                  <div className="iframe-container">
                    <YoutubePlayer src="https://www.youtube.com/watch?v=2kMi6MfmGM8" />
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <section id="footer-btn-video">
                  <div class="container-fluid">
                    <p className="text-grey">
                      Unlock access to invest in a range of Investment
                      Opportunities or Register your environmental project for
                      finance & assistance.
                    </p>

                    <div className="d-flex text-center justify-content-center">
                      <Button className="default-btn" onClick={investPage}>
                        invest
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button className="default-btn" onClick={raisePage}>
                        raise
                      </Button>
                    </div>
                  </div>
                </section>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section id="currentproject">
        <div class="container-fluid">
          <Row>
            <Col span={12}>
              <div className="float-left">
                <h1 style={{ marginBottom: "20px", fontSize: "24px" }}>
                  {" "}
                  INVESTMENT OPPORTUNITIES:
                </h1>
              </div>
            </Col>
            <Col span={12}>
              <div className="float-right">
                <Anchor affix={false}>
                  <a onClick={investOppspage}>See All</a>
                </Anchor>
              </div>
            </Col>
          </Row>

          <div class="project-section">
            <OwlCarousel className="owl-theme" margin={10} nav responsive={responsive}>
              <div class="products" onClick={() => history.push("/investopps")}>
                <div class="product-image">
                  <img src={prjimg} alt="" />
                </div>
                <div class="product-content">
                  <h4>My Healthy Kick</h4>
                  <p className="general-p">
                    MHK is developing a range of healthy, tasty, environmentally
                    conscientious vitamin infused drinks and bars. The
                    commercialisation of the above will then be followed by the
                    development and launch of a range of vitamin infused
                    vegetarian meals. This is on the request of one of the
                    world’s leading soft drink manufacturers who has worked
                    closely in the past with one of the core team founders of
                    MHK.
                  </p>
                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>

              <div class="products">
                <div class="product-image">
                  <img src={storeElectric} alt="" />
                </div>
                <div class="product-content">
                  <h4>Storelectric</h4>
                  <p className="general-p">
                    Storelectric Ltd is a developer of projects using highly
                    efficient forms of Compressed Air Energy Storage (CAES)
                    using existing technologies, at a grid scale, efficiently
                    and cost-effectively.
                  </p>
                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>

              <div class="products">
                <div class="product-image">
                  <img src={prj3img} alt="" />
                </div>
                <div class="product-content">
                  <h4>Global Brand Nominees</h4>
                  <p className="general-p">
                    Global Brand Nominees partners with company and managements
                    of substance and provide this bravado, experience and
                    fundraising talent when needed and move onto other client
                    companies when not. So far they have a 100% record with
                    providing suitable companies with the finance they require.
                  </p>
                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>
              {/* <div class="products">
                                <div class="product-image">
                                    <img src={mexicali} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Solar 350 MX3 – Baja California – 260 MW (100+160)</h4>
                                    <p className='general-p'>Solar Irradiation is captured using photovoltaic panels, which create electricity.
                                        Inverters are used to create alternating current after which the parks are connected to
                                        either to the grid or directly to industry via Private Power Purchase Agreements.
                                        Projects are accredited under Domestic and International Subsidization schemes to
                                        increase revenues further.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={desrt} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Solar 350 MX4 – Hermosillo, Sonora – 300 MW</h4>
                                    <p className='general-p'>Solar Irradiation is captured using photovoltaic panels, which create electricity.
                                        Inverters are used to create alternating current after which the parks are connected to
                                        either to the grid or directly to industry via Private Power Purchase Agreements.
                                        Projects are accredited under Domestic and International Subsidization schemes to
                                        increase revenues further.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={amex} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Super Sol SpA 1.1 85.8MW</h4>
                                    <p className='general-p'>Solar Irradiation is captured using photovoltaic panels, which create electricity.
                                        Inverters are used to create alternating current after which the parks are connected to
                                        either to the grid or directly to industry via Private Power Purchase Agreements.
                                        Projects are accredited under Domestic and International Subsidization schemes to
                                        increase revenues further.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={insand} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Qingyuan 9MW Biomass Cogeneration project</h4>
                                    <p className='general-p'>Biomass (Forest Floor, Wood Chips, Trees) are burnt heating water mixed with ammonia.
                                        Increased pressure turns a turbine and generator producing electricity with excess heat
                                        sold to consumer and industry. Utilisation of excess heat increases efficiency, as long
                                        as trees are replanted emission reductions occur.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={aeries} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Zhejiang Zhongying Wind Power Generation project</h4>
                                    <p className='general-p'>Wind Turbines are installed connected to a gearbox and generator and inverters (to create
                                        alternating current) which produce electricity.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={lanov} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Guizhou Panxian hydropower project</h4>
                                    <p className='general-p'>Power is generated as water is channelled through either Run of River, Diversion or Dam
                                        via turbines which then turn generators which produce electricity less carbon
                                        intensively than fossil fuel based generation.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={windandsolarcombo} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Inner Mongolia Huitong 49.5MW wind power project</h4>
                                    <p className='general-p'>Wind Turbines are installed connected to a gearbox and generator and inverters (to create
                                        alternating current) which produce electricity.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={digger} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Langfang MSW incineration Project</h4>
                                    <p className='general-p'>Municipal Solid Waste is incinerated as opposed to left to decompose resulting in an
                                        aggreate reduction in emissions.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={pointfivr} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Datang Chuanbei 30MW Hydro Power Project</h4>
                                    <p className='general-p'>Power is generated as water is channelled through either Run of River, Diversion or Dam
                                        via turbines which then turn generators which produce electricity less carbon
                                        intensively than fossil fuel based generation.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={onepoint} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Anji MSW incineration Project</h4>
                                    <p className='general-p'>Municipal Solid Waste is incinerated as opposed to left to decompose resulting in an
                                        aggregate reduction in emissions.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={DSC} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Xinjiang Wusu Hydropower Project</h4>
                                    <p className='general-p'>Power is generated as water is channelled through either Run of River, Diversion or Dam
                                        via turbines which then turn generators which produce electricity less carbon
                                        intensively than fossil fuel based generation.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={anarb} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Taizhou Refuse Incineration Power Generation Project</h4>
                                    <p className='general-p'>This project type is also known as Anaerobic Digestion, where MSW is digested in an
                                        Anaerobic reaction to ensure CH4 is produced. CH4 is then collected and used to power
                                        generators creating electricity.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={datangtwo} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Cogeneration Central Heating Project of Datang Hu County Thermoelectric Plant</h4>
                                    <p className='general-p'>A new cogeneration plant is built replacing single cycle fossil fuel generation and
                                        leading to a reduction in emissions for the same outputs.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={imgpoint} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Cogeneration Central Heating Project of Datang Hu County Thermoelectric Plant</h4>
                                    <p className='general-p'>A new cogeneration plant is built replacing single cycle fossil fuel generation and
                                        leading to a reduction in emissions for the same outputs.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="products">
                                <div class="product-image">
                                    <img src={datang} alt="" />
                                </div>
                                <div class="product-content">
                                    <h4>Primary Heating Network of Datang Weihe Power Plant (2×300MW) Cogeneration Retrofit</h4>
                                    <p className='general-p'>A fossil fuel based co-generation facility is retrofitted with modern technology and
                                        other enhancements leading to greater efficiency which results in emission reductions
                                        for the same heat/electricity output.</p>
                                    <div class="project-logo">
                                        <img src={demeclogo} alt="" />
                                    </div>
                                </div>
                            </div> */}
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section id="currentproject">
        <div class="container-fluid">
          <h1 style={{ marginBottom: "20px", fontSize: "24px" }}> REVIEWS:</h1>

          <Row>
            <Col xl={4} lg={4} md={24} sm={24} xs={24}>

              <div class="products">
                <h2
                  style={{
                    marginBottom: "10px",
                    fontSize: "24px",
                    color: "#000",
                    textAlign: "center"
                  }}
                >
                  {" "}
                  EXCELLENT
                </h2>
                <Rate
                  allowHalf
                  defaultValue={5}
                  style={{ display: "flex", justifyContent: "center", }}
                  disabled
                />
                <div className="product-content">
                  <h4 className="text-center mt-1 mb-1">Based on 72 reviews
                  </h4>
                </div>
              </div>
            </Col>

            {/* Owl Carousel for Reviews */}
            <Col xl={16} lg={16} md={24} sm={24} xs={24}>
              <div class="project-section">
                <OwlCarousel className="owl-theme" loop margin={10} nav responsive={responsive}>
                  <div class="products">
                    {/* <div class="product-image">
                                    <img src={desert2} alt="" />
                                </div> */}
                    <Rate
                      allowHalf
                      defaultValue={5}
                      style={{ display: "flex", paddingLeft: "13px" }}
                      disabled
                    />
                    <div class="product-content">
                      <h4 className="font-weight-bold">Excellent Platform, working for climate change</h4>
                      <p>
                        DEMEC9 is a really informative platform including all the research work into environmental and climate change. It also has quite unique investment opportunities with a distinctive milestone based investments.
                      </p>
                      <p className="text-grey mt-1">Mat Fraser</p>
                      {/* <div className="project-logo">
                        <img src={demeclogo} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div class="products">
                    {/* <div class="product-image">
                                    <img src={mexicali} alt="" />
                                </div> */}
                    <Rate
                      allowHalf
                      defaultValue={5}
                      style={{ display: "flex", paddingLeft: "13px" }}
                      disabled
                    />

                    <div class="product-content">
                      <h4 className="font-weight-bold">
                        Excellent Platform, working for climate change
                      </h4>
                      <p>
                        DEMEC9 is a really informative platform including all the research work into environmental and climate change. It also has quite unique investment opportunities with a distinctive milestone based investments.
                      </p>
                      <p className="text-grey mt-1">Mat Fraser</p>
                      {/* <div className="project-logo">
                        <img src={demeclogo} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div class="products">
                    {/* <div class="product-image">
                                    <img src={desrt} alt="" />
                                </div> */}
                    <Rate
                      allowHalf
                      defaultValue={5}
                      style={{ display: "flex", paddingLeft: "13px" }}
                      disabled
                    />
                    <div class="product-content">
                      <h4 className="font-weight-bold">Excellent Platform, working for climate change</h4>
                      <p>
                        DEMEC9 is a really informative platform including all the research work into environmental and climate change. It also has quite unique investment opportunities with a distinctive milestone based investments.
                      </p>
                      <p className="text-grey mt-1">Mat Fraser</p>
                      {/* <div className="project-logo">
                        <img src={demeclogo} alt="" />
                      </div> */}
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
            <Col xl={4} lg={4} md={24} sm={24} xs={24}>
              <div class="products">
                {/* <div class="product-image">
                                    <img src={desert2} alt="" />
                                </div> */}
                <div class="product-content text-center">
                  <Button
                    className="default-btn"
                    onClick={showModal}
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                    }}
                  >
                    Leave a review
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section id="currentproject">
        <div class="container-fluid">
          <h1 style={{ marginBottom: "20px", fontSize: "24px" }}>
            {" "}
            CURRENT PROJECTS:
          </h1>
          <div class="project-section">
            <OwlCarousel className="owl-theme" loop margin={10} nav responsive={responsive}>
              <div class="products">
                <div class="product-image">
                  <img src={desert2} alt="" />
                </div>
                <div class="product-content">
                  <h4>Super Sol SpA 1.2 90MW</h4>
                  <p className="general-p">
                    Solar Irradiation is captured using photovoltaic panels,
                    which create electricity. Inverters are used to create
                    alternating current after which the parks are connected to
                    either to the grid or directly to industry via Private Power
                    Purchase Agreements. Projects are accredited under Domestic
                    and International Subsidization schemes to increase revenues
                    further.
                  </p>

                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>
              <div class="products">
                <div class="product-image">
                  <img src={mexicali} alt="" />
                </div>
                <div class="product-content">
                  <h4>Solar 350 MX3 – Baja California – 260 MW (100+160)</h4>
                  <p className="general-p">
                    Solar Irradiation is captured using photovoltaic panels,
                    which create electricity. Inverters are used to create
                    alternating current after which the parks are connected to
                    either to the grid or directly to industry via Private Power
                    Purchase Agreements. Projects are accredited under Domestic
                    and International Subsidization schemes to increase revenues
                    further.
                  </p>

                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>
              <div class="products">
                <div class="product-image">
                  <img src={desrt} alt="" />
                </div>
                <div class="product-content">
                  <h4>Solar 350 MX4 – Hermosillo, Sonora – 300 MW</h4>
                  <p className="general-p">
                    Solar Irradiation is captured using photovoltaic panels,
                    which create electricity. Inverters are used to create
                    alternating current after which the parks are connected to
                    either to the grid or directly to industry via Private Power
                    Purchase Agreements. Projects are accredited under Domestic
                    and International Subsidization schemes to increase revenues
                    further.
                  </p>

                  <div class="project-logo">
                    <img src={demeclogo} alt="" />
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <Modal
        title="Review"
        visible={isModalVisible}
        style={{ marginTop: "100px" }}
        onCancel={handleCancel}
        // closable={true}
        footer={null}
      >
        <Spin spinning={loading}>
          <p>
            <Rate
              allowHalf
              defaultValue={5}
              style={{ display: "flex", justifyContent: "center" }}
              onChange={onChangeRateHandler}
            />
            ,
          </p>
          <Form name="basic" onFinish={reviewHandler} formLayout="vertical">
            <Form.Item name="title">
              <Input size="large" placeholder="title" />
            </Form.Item>
            <Form.Item name="comment">
              <Input.TextArea
                size="large"
                placeholder="leave a comment"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
            <Button
              className="default-btn"
              htmlType="submit"
              style={{
                color: "white",
                fontSize: "20px",
                marginTop: "20px",
                height: "60px",
              }}
            >
              Submit
            </Button>
          </Form>
        </Spin>
      </Modal>

      <section id="currentproject">
        <div class="container-fluid">
          <h1 style={{ marginBottom: "20px", fontSize: "24px" }}>
            {" "}
            PAST PROJECTS:
          </h1>

          <div class="project-section">
            <OwlCarousel className="owl-theme" loop margin={10} nav responsive={responsive}>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={threeindia} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Anning River Wa’nao Hydropower Station in Xichang City
                      Liangshan Prefecture
                    </h4>
                    <p>
                      Power is generated as water is channeled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={compost} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Municipal Solid Waste based Composting at Kolhapur,
                      Maharashtra
                    </h4>
                    <p>
                      Municipal Solid Waste is composted aerobically as opposed
                      to anaerobically, thus waste gas is CO2, not CH4,
                      resulting in reduced emissions based on GWP of gases.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={taizhou} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Taizhou Municipal Solid Waste Power Generation</h4>
                    <p>
                      This project type is also known as Anaerobic Digestion,
                      where MSW is digested in an Anaerobic reaction to ensure
                      CH4 is produced. CH4 is then collected and used to power
                      generators creating electricity.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={IMGipoint} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Shantou Municipal Solid Waste Power Generation</h4>
                    <p>
                      This project type is also known as Anaerobic Digestion,
                      where MSW is digested in an Aanerobic reaction to ensure
                      CH4 is produced. CH4 is then collected and used to power
                      generators creating electricity.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={hydroeight} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yongping County Dawantang 1st Level Hydropower Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={smallhydrofive} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yunnan Yongde County Dedanghe 3rd Cascade Hydropower
                      Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={huayyangtwo} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Hebei Yuaishi Huaiyang Biomass Coproduction</h4>
                    <p>
                      Biomass (Forest Floor, Wood Chips, Trees) are burnt
                      heating water mixed with ammonia. Increased pressure turns
                      a turbine and generator producing electricity with excess
                      heat sold to consumer and industry. Utilisation of excess
                      heat increases efficiency, as long as trees are replanted
                      emission reductions occur.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={hydrosevensixeight} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Fang County Wafangping 2nd Level Hydropower Project</h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={wasteelec} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Jinshan Municipal Solid Waste Power Generation</h4>
                    <p>
                      This project type is also known as Anaerobic Digestion,
                      where MSW is digested in an Anaerobic reaction to ensure
                      CH4 is produced. CH4 is then collected and used to power
                      generators creating electricity.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={ccssilo} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Qinghai Xining Calcium Carbide Residue Utilization for
                      Clinker Production Project
                    </h4>
                    <p>
                      The project activity plans to utilise calcium carbide
                      residue (fly ash) generated from blast furnace to
                      partially replace carbonates as a raw material in cement
                      manufacture. A reduction in process emissions is the
                      result.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={colapoint} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Coca Coda Sinclair 1500 MW Hydro Electric Power Station,
                      Ecuador, South America
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={smallhydrodashsix} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yunnan Yongde County Manghaihe 6th and 7th Cascade Bundled
                      Small Hydropower Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={sichuan} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yunnan Yongde County Manglinghe 2nd and 3rd Cascade
                      Bundled Small Hydropower Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={henantwo} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Henan Changyuan Biogas Power Project</h4>
                    <p>
                      Biogas has been harvested. Collecting the CH4 and then
                      utilising the gas to power a generator produces
                      electricity, with CO2 becoming the waste gas.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={IMGtwobeing} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Sichuan Da County Jiujietan Hydropower Project</h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={hydrosevenpoint} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yulong County Gezihe 1st Level and Gezihe 2nd Level Bundle
                      Hydropoewr Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={altebliech} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Yongping County Dawantang 2nd Level Hydropower Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={jinyuan} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Guangxi Jinyuan Methane Recovery Project</h4>
                    <p>
                      An industrial process has created CH4 as a waste gas.
                      Collecting the CH4 and then utilising the gas to power a
                      generator produces electricity, with CO2 becoming the
                      waste gas.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={hydrofouripoint} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>
                      Kuanping 1st Level and Kuanping 2nd Level Bundle Small
                      Hydropower Project
                    </h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={hydrothreeonepoint} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Pengshui County Xiamakou Hydropower Project</h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={anotherDSC} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Guangxi Heng County Methane Recovery Project</h4>
                    <p>
                      An industrial process has created CH4 as a waste gas.
                      Collecting the CH4 and then utilising the gas to power a
                      generator produces electricity, with CO2 becoming the
                      waste gas.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="products">
                  <div class="product-image">
                    <img src={zinjangaltei} alt="" />
                  </div>
                  <div class="product-content">
                    <h4>Xingjiang Aletai Kesaiyi hydropower project</h4>
                    <p>
                      Power is generated as water is channelled through either
                      Run of River, Diversion or Dam via turbines which then
                      turn generators which produce electricity less carbon
                      intensively than fossil fuel based generation.
                    </p>
                    <div class="project-logo">
                      <img src={demeclogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section id="footer-btn">
        <div class="container-fluid">
          <div className="d-flex text-center justify-content-center">
            <Button className="default-btn" onClick={investPage}>
              invest
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button className="default-btn" onClick={raisePage}>
              raise
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
