import React, { useState } from 'react';
import DashboardHeader from '../common/header';


import { useHistory } from 'react-router-dom';


import { Tabs, Row, Col, Radio, Space, Card, Button, Slider, InputNumber, message, } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

export default function Myinvestments() {
    const history = useHistory();
    const [inputValue, setInputValue] = useState(0);


    const onChange = value => {
        setInputValue(value);
    };

    const handleSubmit = () => {
        history.push('/investopps');
    }
    return (
        <>
            <DashboardHeader />
            <div>
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">

                        <WarningTwoTone style={{ fontSize: "100px", marginTop: "80px" }} />
                    </div>
                    </Col>
                </Row>
                <Row style={{ marginTop: "50px" }}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">

                        <h3 style={{ marginBottom: "10px", fontSize: "20px", padding: "10px 30px" }} >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  You have not invested in any of the Opportunities.<br></br>
                            For the latest Investment Opportunities, please click below:
                        </h3>
                    </div>

                    </Col>
                </Row>
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center mb-2">

                        <Button className="default-btn" htmlType="submit" onClick={handleSubmit}>Invest</Button>
                    </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}