import React, { useState } from "react";
import {
  Tabs,
  Row,
  Col,
  Radio,
  Space,
  Card,
  Button,
  Slider,
  InputNumber,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { CopyTwoTone } from "@ant-design/icons";
import DashboardHeader from "../common/header";

export default function MileStone() {
  const history = useHistory();
  const [inputValue, setInputValue] = useState(0);

  const gridStyle = {
    width: "100%",
    padding: "30px",
  };

  const spacing = {
    paddingLeft: "30px",
  };

  const onChange = (value) => {
    setInputValue(value);
  };

  const handleSubmit = () => {
    console.log(inputValue);
    if (inputValue === 0) {
      message.info({
        content: "Please select milestones",
        style: { marginTop: "20vh" },
        duration: 4,
      });
    } else {
      localStorage.setItem("milestones", inputValue);
      history.push("/milestoneform");
    }
  };

  return (
    <>
      <DashboardHeader />
      <div>
        <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <div className="text-center">
            {/* <QuestionCircleTwoTone style={{ fontSize: "100px", marginTop: "80px" }} /> */}
            <CopyTwoTone style={{ fontSize: "100px", marginTop: "80px" }} />
            </div>
          </Col>
        </Row>
        <Row span={24} style={{ marginTop: "50px" }}>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={2}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>

          <Col xl={16} lg={16} md={16} sm={20} xs={20}>
            <h3 style={{ marginBottom: "10px", fontSize: "20px" }}>
              Now that you understand how to fill out the milestones of your
              project/company as indicated in the downloaded template. Lets
              Proceed further:
            </h3>
            <h3 style={{ marginBottom: "10px", fontSize: "20px" }}>
              How many milestones do you need to achieve to provide an exit for
              your investors ?
            </h3>
            <Card bordered={false} className="mb-3">
              <Card.Grid style={gridStyle} hoverable={false}>
                <Slider
                  min={1}
                  max={15}
                  onChange={onChange}
                  value={typeof inputValue === "number" ? inputValue : 0}
                />
                <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="text-center">
                    <Button
                      className="default-btn"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      Next
                    </Button>
                    </div>
               
                  </Col>
                </Row>
              </Card.Grid>
            </Card>
          </Col>
          <Col
            xl={4}
            lg={4}
            md={4}
            sm={2}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>
        </Row>
      </div>
    </>
  );
}
