import React, { useState } from 'react';
import { Tabs, Row, Col, Form, Input, Button, Card, Spin, message, Checkbox, Anchor } from 'antd';
import { apiInstance } from '../../config/axios.config';
import { useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';

export default function ForgetPassword() {
    const { TabPane } = Tabs;
    const { Link } = Anchor;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    function callback(key) {
        console.log(key);
    }
    const onChangeanchoe = (link) => {
        console.log('Anchor:OnChange', link);
    };

    const onFinishLogin = async (values) => {
        try {
            console.log('Success:', values);

            const { email, } = values;
            localStorage.setItem('email', email);
            setLoading(true);
            const response = await apiInstance.post('forget-password', {
                email: email,
            });
            const { status } = response.data;
            localStorage.setItem("userId", response.data.data._id);
            if (status) {
                setLoading(false);
                message.success({ content: 'OTP sent successfully!', style: { marginTop: '20vh' } });
                history.push('/verify-opt');
            } else {
                setLoading(false);
                message.info({ content: 'Email not found!', style: { marginTop: '20vh' } });
            }
        } catch (error) {
            setLoading(false);
            message.error({ content: 'something went wrong, please try again!', style: { marginTop: '20vh' } });
        }
    };

    const signUp = () => {
        history.push('/auth');
    }

    const investOppspage = () => {
        history.push('/investopps');
    }

    const gotoContact = () => {
        history.push('/contact');
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }
    return (
        <Spin size="large" spinning={loading}>
            <header id="header">
                <div class="container-fluid">
                    <div class="header-wrap clearfix">
                        <div class="header-left">
                            <div class="logo">
                                <a href="/"></a>
                            </div>
                        </div>
                        <div class="header-right">
                            <div class="top-contactdetail">
                                <ul>
                                    <li><a href="#"><i class="fab fa-facebook"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a><MailOutlined />      hello@demec9.com</a></li>
                                </ul>
                            </div>
                            <div class="menu">
                                <ul>
                                    {/* <li><a onClick={investPage}>invest</a></li>
                                    <li><a onClick={raisePage}>raise</a></li> */}
                                    <li><a onClick={investOppspage}>Investment Opportunities</a></li>
                                    <li><a href="https://demec9.com/blog/">Research</a></li>
                                    <li><a onClick={gotoContact}>contact</a></li>
                                    <li><a onClick={signUp}>Sign Up</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div>
                <Row gutter={24}>
                <Col xl={8} lg={8} md={4} sm={2} xs={2}  style={{ marginTop: "100px" }}></Col>
                    <Col xl={8} lg={8} md={16} sm={20} xs={20} style={{ marginTop: "100px" }}>
                        <Card className="cardStyle mb-3">
                            <Tabs defaultActiveKey="1" onChange={callback} centered type="card">
                                <TabPane tab="Forgot Password" key="1">
                                    <Form
                                        name="basic"
                                        initialValues={{ remember: true }}
                                        onFinish={onFinishLogin}
                                        formLayout="vertical"
                                    >
                                        <h3 style={{ marginBottom: "10px" }} className="text-grey"> 
                                            Enter your email:
                                        </h3>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Please input your username!' }]}
                                        >
                                            <Input placeholder="EMAIL" size="large" />
                                        </Form.Item>

                                        <Form.Item>
                                        <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                                                    {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                        Submit
                                                    </Button> */}
                                                    <div className="text-center">
                                                        
                                                    <Button className="default-btn" htmlType="submit" style={{width: 200}}>Send Email</Button>
                                                    </div>    

                                                    
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                    <Col xl={8} lg={8} md={4} sm={2} xs={2}  style={{ marginTop: "100px" }}></Col>
                </Row>
            </div>
        </Spin>
    );
}
