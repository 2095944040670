import React, { useState } from "react";
import {
  Tabs,
  Row,
  Col,
  Form,
  Input,
  Button,
  Menu,
  Card,
  Spin,
  message,
  Checkbox,
  Anchor,
  Layout
} from "antd";
import {
  MenuOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import { apiInstance } from "../../config/axios.config";
import { useHistory } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import isEmail from "validator/lib/isEmail";
import demeclogo from "../../images/logo.png";
import DashboardHeader from "../common/header";

export default function Auth() {
  const { TabPane } = Tabs;
  const { Link } = Anchor;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { Header, Content } = Layout;

  const logoClick = () => {
    history.push("/");
  };

  function callback(key) {
    console.log(key);
  }
  const onChangeanchoe = (link) => {
    console.log("Anchor:OnChange", link);
  };

  const onFinishLogin = async (values) => {
    try {
      console.log("Success:", values);

      const { email, password } = values;
      if (isEmail(email)) {
        setLoading(true);
        const response = await apiInstance.post("/user/login", {
          email: email,
          password: password,
        });
        const { status } = response.data;
        if (status) {
          setLoading(false);
          localStorage.setItem("userId", response.data.data._id);
          localStorage.setItem("email", response.data.data.email);
          message.success({
            content: "User logged in successfully!",
            style: { marginTop: "20vh" },
          });
          if (response.data.data.isProfileCompleted) {
            history.push(`/`);
          } else {
            history.push(`/profile/${response.data.data._id}`);
          }
        } else {
          setLoading(false);
          message.info({
            content: "User not authorize!",
            style: { marginTop: "20vh" },
          });
        }
      } else {
        message.info({
          content: "Invalid Email Address",
          style: { marginTop: "20vh" },
        });
      }
    } catch (error) {
      setLoading(false);
      message.error({
        content: "Email or Password not matched!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const onFinishFailedLogin = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    try {
      console.log("Success:", values);

      const { email, password, confirmPassword, remember } = values;
      console.log(isEmail(email));
      if (isEmail(email)) {
        if (!remember)
          return message.info({
            content: "Please accept privacy policy!",
            style: {
              marginTop: "20vh",
            },
          });

        if (password === confirmPassword) {
          setLoading(true);
          const response = await apiInstance.post("/user/signup", {
            email: email,
            password: password,
          });
          const { status } = response.data;
          if (status) {
            setLoading(false);
            message.success({
              content:
                "User created successfully, please check your email to proceed!",
              style: { marginTop: "20vh" },
              duration: 4,
            });
          } else {
            setLoading(false);
            message.success({
              constent: "Duplicate Email Address!",
              style: { marginTop: "20vh" },
              duration: 4,
            });
          }
        } else {
          message.info({
            content: "Password mismatch",
            style: { marginTop: "20vh" },
          });
        }
      } else {
        message.info({
          content: "Invalid Email Address",
          style: { marginTop: "20vh" },
        });
      }
    } catch (error) {
      setLoading(false);
      message.error({
        content: "Email Address already exists!",
        style: { marginTop: "20vh" },
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const loginauth = () => {
    console.log("ready for login");
  };

  const signUp = () => {
    history.push("/auth");
  };

  const investOppspage = () => {
    history.push("/investopps");
  };

  const gotoContact = () => {
    history.push("/contact");
  };

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  function forgetPassword(e) {
    e.preventDefault();
    console.log("i am hitting ,,,");
    history.push("/forget-password");
  }
  return (
    <Spin size="large" spinning={loading}>
      <DashboardHeader />
      <div>
        <Row>
          <Col
            xl={8}
            lg={8}
            md={4}
            sm={2}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>
          <Col
            Col
            xl={8}
            lg={8}
            md={16}
            sm={20}
            xs={20}
            style={{ marginTop: "100px" }}
          >
            <Card className="cardStyle">
              <Tabs
                defaultActiveKey="1"
                onChange={callback}
                centered
                type="card"
              >
                <TabPane tab="Login" key="1">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailedLogin}
                    formLayout="vertical"
                  >
                    <h3 style={{ marginBottom: "10px" }}>Enter your email:</h3>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input placeholder="EMAIL" size="large" />
                    </Form.Item>
                    <h3 style={{ marginBottom: "10px" }}>
                      Enter your password:
                    </h3>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="PASSWORD" size="large" />
                    </Form.Item>

                    <Row>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <div className='float-right'>
                          <Anchor affix={false} onClick={forgetPassword}>
                            <Link title="Forgot Password" />
                          </Anchor>
                        </div>

                      </Col>
                    </Row>

                    <Form.Item>
                      <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                    Submit
                                                </Button> */}
                          <div className="text-center">
                            <Button className="default-btn" htmlType="submit">
                              Login
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </TabPane>
                <TabPane tab="Signup" key="2">
                  <Row >
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <h1>Open an Account</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <p className="text-grey mb-1-px">
                        Unlock access to invest in a Range of Investment Opportunities
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <p className="text-grey mb-1-px">
                        Or Register Your own Project/Company for a Raise.
                      </p>
                    </Col>
                  </Row>
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <h3
                      className="p-14 text-grey"
                      style={{ marginBottom: "10px" }}
                    >
                      Enter your email:
                    </h3>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input placeholder="EMAIL" size="large" />
                    </Form.Item>
                    <h3
                      style={{ marginBottom: "10px" }}
                      className="p-14 text-grey"
                    >
                      Create your password:
                    </h3>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password placeholder="PASSWORD" size="large" />
                    </Form.Item>
                    <h3 style={{ marginBottom: "10px" }}>
                      Confirm your password:
                    </h3>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please input your confirm password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="CONFIRM PASSWORD"
                      />
                    </Form.Item>
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox onChange={onChange}>
                        <span className="text-grey">
                          I have read and agree to the privacy policy.
                        </span>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Row >
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          {/* <Button htmlType="submit" onClick={loginauth} block className="buttonColor">
                                                    Submit
                                                </Button> */}
                          <div className="text-center">

                            <Button
                              className="default-btn"
                              htmlType="submit"
                              onClick={loginauth}

                            >
                              Create Account
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col
            xl={8}
            lg={8}
            md={4}
            sm={``}
            xs={2}
            style={{ marginTop: "100px" }}
          ></Col>
        </Row>
      </div>
    </Spin>
  );
}
