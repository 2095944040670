import React, { useState } from 'react';

import { Form, Input, Button, Checkbox, Row, Col } from 'antd';

import axios from 'axios';


export default function TestForm() {

    const [sum, setSum] = useState(0);

    const [PreMoneyValuation, setPreMoneyValuation] = useState(0);

    const [PhysicalCashInvested, setPhysicalCashInvested] = useState(0);


    let identity = 768;
    let key = "PostMoneyValuation";
    let finalVals = {};
    let resulting;
    let final = 0;

    const apiCallBack = async () => {
        const response = await axios.post(`https://jsonplaceholder.typicode.com/users`, { finalVals });
        console.log(response);
    }

    const onFinishTwo = (values) => {

        finalVals = values;
        finalVals[key] = +final;
        console.log(finalVals);
        identity = +values.PreMoneyValuation;
        resulting = +values.PhysicalCashInvested;
        final = identity + resulting;
        setSum(final);
        finalVals[key] = +final;
        console.log("Success:", finalVals);
        apiCallBack();
    };

    const onFinishFailedTwo = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const sumValues = (e) => {
        setPhysicalCashInvested(+e.target.value);
        setSum(+e.target.value + PreMoneyValuation);
    }


    return (
        <>

            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinishTwo}
                onFinishFailed={onFinishFailedTwo}
            >
                <Form.Item label="Milestone Name" name="MilestoneName"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your username!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Milestone Explanation" name="MilestoneExplanation"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Pre-Money Valuation" name="PreMoneyValuation" onChange={(e) => { setPreMoneyValuation(+e.target.value) }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Physical Cash Invested" name="PhysicalCashInvested" onChange={(e) => { sumValues(e) }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input disabled={!PreMoneyValuation} />
                </Form.Item>

                {/* ------------------------------------------------------------------------------------------------> */}
                <Row span={24}>
                    <Col span={16} offset={8}>
                        <Input value={(PreMoneyValuation && PhysicalCashInvested) ? sum : null} style={{ marginBottom: "20px" }} size="large" addonBefore="Post Money Valuation" placeholder="Post Money Valuation" disabled />
                    </Col>
                </Row>
                {/* ------------------------------------------------------------------------------------------------> */}

                <Form.Item label="Percentage of Company Acquired at the time of Investment" name="PercentageofCompanyInvestment"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Shares Allocated" name="SharesAllocated"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Share Float Post" name="ShareFloatPost"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Share Price" name="SharePrice"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}