import React, { useState } from 'react';
import { Tabs, Row, Col, Form, Input, Button, Card, Spin, message, Select, Anchor } from 'antd';
import { apiInstance } from '../../config/axios.config';
import { useHistory } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import DashboardHeader from '../common/header';

export default function Blog() {
    const { TabPane } = Tabs;
    const { Link } = Anchor;
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [selectValue, setSelectValue] = useState();
    const { Option } = Select;


    const anArr = ['European Solar', 'Tyre Recycling', 'Listed Environmental Investments', 'Ocean Energy', 'Electric Car?'];
    const children = [];

    anArr.forEach((e, i) => { children.push(<Option key={e}>{e}</Option>) });



    function callback(key) {
        console.log(key);
    }

    function handleChange(value) {
        console.log(value);
        setSelectValue(value);
    }


    const onFinish = async (values) => {
        try {
            console.log(selectValue, values);

            const { contactMessage, email, name, phoneNumber, } = values;
            setLoading(true);
            const response = await apiInstance.post('/email/blog', {
                email: email, name: name, message: contactMessage, phoneNumber: phoneNumber, reseachArticle: selectValue,
            });
            const { status } = response.data;
            if (status) {
                
                message.success({ content: 'Blog email sent successfully!', style: { marginTop: '20vh' } });
                history.push('/');
            }
        } catch (error) {
            console.log(error);
            message.error({ content: 'Something went wrong please try again!', style: { marginTop: '20vh' } });
        } finally {
            setLoading(false);
        }
    };






    return (
        <Spin size="large" spinning={loading}>
            <DashboardHeader />
            <div>
                <Row>
                <Col xl={8} lg={8} md={4} sm={2} xs={2} style={{ marginTop: "100px" }}></Col>
                <Col xl={8} lg={8} md={16} sm={20} xs={20} style={{ marginTop: "100px" }}>
                        <Card className="cardStyle mb-3">
                            <Tabs defaultActiveKey="1" onChange={callback} centered type="card">
                                <TabPane tab="Free Research" key="1">
                                    <Form
                                        name="basic"
                                        formLayout="vertical"
                                        onFinish={onFinish}
                                        formLayout="vertical"
                                    >
                                        <h3 style={{ marginBottom: "10px" }}>
                                            Enter your Full Name:
                                        </h3>
                                        <Form.Item
                                            name="name"
                                            rules={[{ required: true, message: 'Please input your name!' }]}
                                        >
                                            <Input placeholder="Enter your name" size="large" />
                                        </Form.Item>
                                        <h3 style={{ marginBottom: "10px" }}>
                                            Enter your Email:
                                        </h3>
                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: 'Please input your email!' }]}
                                        >
                                            <Input placeholder="Email" size="large" />
                                        </Form.Item>

                                        <h3 style={{ marginBottom: "10px" }}>
                                            Enter your Phone Number:
                                        </h3>
                                        <Form.Item
                                            name="phoneNumber"
                                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                                        >
                                            <Input placeholder="Phone" size="large" />
                                        </Form.Item>

                                        <h3 style={{ marginBottom: "10px" }}>
                                            Research Articles:
                                        </h3>
                                        <Form.Item
                                            name="researchArticle"
                                            rules={[{ required: false, message: 'Please input your research article!' }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Please select"
                                                defaultValue={[]}
                                                onChange={handleChange}
                                            >
                                                {children}
                                            </Select>,
                                        </Form.Item>

                                        <h3 style={{ marginBottom: "10px" }}>
                                            Type a Message:
                                        </h3>
                                        <Form.Item
                                            name="contactMessage"
                                            rules={[{ required: true, message: 'Please input your message!' }]}
                                        >
                                            <Input.TextArea placeholder="Message..." size="large" rows={4} />
                                        </Form.Item>



                                        <Form.Item>
                                            <Row>
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                    {/* <Button type="primary" htmlType="submit" block className="buttonColor">
                                                    Submit
                                                </Button> */}
                                                <div className="text-center">

                                                    <Button className="default-btn" htmlType="submit" style={{ color: "white", fontSize: "20px", marginTop: "20px", height: "60px" }}>Send</Button>
                                                </div>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                    <Col xl={8} lg={8} md={4} sm={2} xs={2} style={{ marginTop: "100px" }}></Col>
                </Row>
            </div>
        </Spin>
    );
}